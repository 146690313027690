<div class="player-block-container" fxFlex>

    <div class="overlay-dark"></div>

    <ng-container *ngIf="block !== null">

        <!-- EXERCISE BLOCK (1)-->
        <div class="block-exercise" [ngClass]="{'block-next': isNext}" *ngIf="block.type === 1">
            <ng-container *ngIf="block !== null">
                <div class="block-title block-title-gray" *ngIf="!isNext">
                    {{ block.item.name }}
                </div>

                <!-- <div class="block-next-countdown" *ngIf="nextCountdown && !isNext">
                    {{ nextCountdown }} {{ 'PLAYER.SECONDS_ABREV' | translate}}
                </div> -->

                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100" fxFill>
                    <div class="block-exercise-image text-center"
                        *ngIf="globalService.isMobile || isNext">
                        <img class="block-exercise-image-inner" [src]="block.item.gif">
                    </div>
                    <video class="block-exercise-image" autoplay loop *ngIf="!isNext && !globalService.isMobile"
                        poster="/assets/img/player-loading-video.png" [muted]="'muted'" [attr.src]="block.item.video">
                        <!-- <source [attr.src]="block.item.video" type="video/mp4"> -->
                    </video>
                    <!-- <div *ngIf="globalService.isMobile">
                        <img [src]="block.item.gif">
                    </div> -->
                </div>
            </ng-container>
        </div>

        <!-- VIDEO BLOCK (2)-->
        <div class="block-video" *ngIf="block.type === 2">
            <div [id]="'playerContainer' + this.block.id" class="block-video-int"></div>
        </div>

        <!-- LAYER BLOCK (3)-->
        <div class="block-layer" *ngIf="block.type === 3">

            <!-- BEHAVIOUR (0): NONE -->
            <ng-container>
                <ng-container>
                    <div class="block-exercise-image" [ngStyle]="{'background-image':'url('+ block.item.image+')'}">
                    </div>
                    <div [ngClass]="getLayerTitlePositionClasses(block)" *ngIf="block.item.text">
                        {{ block.item.text }}
                    </div>
                    <!-- <div class="block-next-countdown" *ngIf="nextCountdown && !isNext && block.item.behaviour !== 1">
                        {{ nextCountdown }} {{ 'PLAYER.SECONDS_ABREV' | translate}}
                    </div> -->
                </ng-container>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100 pos-abs top-0"
                    fxFill>
                    <!-- BEHAVIOUR (1): COUNTDOWN -->
                    <div [ngClass]="{'block-countdown-numbers': block.item.image===null, 'block-countdown-numbers-corner': block.item.image!==null}"
                        *ngIf="block !== null && block.item.behaviour === 1">
                        {{(- mainTime + block.initTime) + block.duration}}
                    </div>
                </div>
            </ng-container>

        </div>


    </ng-container>

</div>