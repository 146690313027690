<div class="gallery-name">
    {{gallery.name}}
</div>
<div *ngIf="gallery.workouts.length === 0">
    {{'GALLERY.NO_ITEMS' | translate}}
</div>
<div class="gallery" *ngIf="gallery.workouts.length > 0">

    <div [id]="'prev-slide-' + galleryRandomId" class="nav-btn prev-slide"><span class="mdi mdi-chevron-left"></span>
    </div>
    <swiper fxFlex [config]="swiperOptions">
        <div *ngFor="let item of gallery.workouts" class="swiper-slide">
            <!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            {{item.name}}
          </div> -->
            <!-- <ng-container carouselSlide [width]="285" [id]="item.id.toString()"> -->
            <div class="gallery-card cursor-pointer" fxLayout="column" fxLayoutAlign="space-between stretch"
                (click)="openElement(item.id)">
                <div class="gallery-card-image" [ngStyle]="{'background-image':'url('+item.image+')'}">
                </div>
                <div class="gallery-label-new" *ngIf="isNew(item.date)">
                    {{'GALLERY.NEW' | translate}}
                </div>
                <div class="gallery-card-info h-100" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="gallery-card-specs" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <div class="label-black-small">{{item.level.name}}</div>
                        <div class="label-black-small">{{item.mode.name}}</div>
                        <div class="label-black-small"><span class="mdi mdi-av-timer"></span>
                            {{item.duration | tominutes}}
                            {{'GALLERY.TIME_MINUTES' | translate}}</div>
                    </div>
                    <div class="pt-05" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <div class="label-white-small" *ngIf="item.materials.length === 0">
                            {{'FILTER.NO_MATERIAL' | translate}}
                        </div>
                        <div class="label-white-small" *ngFor="let material of item.materials">
                            {{material.name}}
                        </div>
                    </div>
                    <div class="gallery-card-title">
                        <h3>{{item.name}}</h3>
                    </div>
                    <div class="gallery-card-description" fxFlex>
                        <p class="mt-0" *ngIf="item.description">
                            {{(item.description.length>150)? (item.description | slice:0:150)+' ...':(item.description)}}
                        </p>
                    </div>
                </div>
            </div>
            <!-- </ng-container> -->
        </div>
    </swiper>
    <div [id]="'next-slide-' + galleryRandomId" class="nav-btn next-slide"><span class="mdi mdi-chevron-right"></span>
    </div>

</div>
<div [id]="'pagination-' + galleryRandomId" class="swiper-paginator text-center mt-1"></div>