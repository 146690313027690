import { Component, OnInit } from '@angular/core';
import { constants } from './../../../../assets/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public rrss;

  constructor() {
    this.rrss = constants.rrss;
  }

  ngOnInit() {
  }

}
