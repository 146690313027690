<div #sidemenu class="side-menu" [ngClass]="{'menu-enabled': this.menuEnabled, 'menu-disabled': !this.menuEnabled}">
    <div class="user-card">
        <div class="user-avatar">
            <img [src]="this.userImage" alt="User avatar" *ngIf="this.userImage">
            <img src="/assets/img/user.png" alt="User avatar" *ngIf="!this.userImage">
        </div>
        <div class="user-name-cont" *ngIf="this.userName">
            <div class="user-name">
                {{this.userName}}
            </div>
        </div>
    </div>
    <div class="menu-list">
        <!-- <div routerLink="" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="menu-list-row">
            <img src="assets/img/icon_menu_dashboard.png" />
            <div>{{'SIDEMENU.DASHBOARD' | translate}}</div>
        </div> -->
        <!-- <div routerLink="" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="menu-list-row">
            <div class="menu-item-notification"></div>
            <img src="assets/img/icon_menu_profile.png" />
            <div>{{'SIDEMENU.PROFILE' | translate}}</div>
        </div> -->
        <!-- <div routerLink="" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="menu-list-row">
            <img src="assets/img/icon_menu_rankings.png" />
            <div>{{'SIDEMENU.RANKINGS' | translate}}</div>
        </div> -->
        <div class="menu-list-row">
            <div routerLink="" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" >
                <img src="assets/img/icon_menu_homefitness.png" />
                <div>{{'SIDEMENU.HOME_FITNESS' | translate}}</div>
            </div>
            <div >
                <ul>
                    <li routerLink="/workouts">workouts</li>
                </ul>
            </div>
        </div>
        <div routerLink="" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="menu-list-row" (click)="authService.logOut()">
            <img src="assets/img/icon_menu_logout.png" />
            <div>{{'SIDEMENU.LOG_OUT' | translate}}</div>
        </div>

    </div>

</div>