import { Component, NgZone, OnDestroy, Output, EventEmitter, Input, } from '@angular/core';

import { BLE } from '@ionic-native/ble/ngx';
import { BluetoothCore, BrowserWebBluetooth, ConsoleLoggerService } from '@manekinekko/angular-web-bluetooth';
import { BleService } from 'src/app/services/ble.service';
import { Subscription } from 'rxjs';
import { constants } from '../../../../assets/constants';

// export const HEART_RATE_SERVICE_UUID = '0000180d-0000-1000-8000-00805f9b34fb';
// export const HEART_RATE_MEASUREMENT_CHARACTERISTIC = '00002a37-0000-1000-8000-00805f9b34fb';
export const HEART_RATE_SERVICE_UUID = '180D';
export const HEART_RATE_MEASUREMENT_CHARACTERISTIC = '2A37';

export const bleCore = (b: BrowserWebBluetooth, l: ConsoleLoggerService) => new BluetoothCore(b, l);
export const bleService = (b: BluetoothCore) => new BleService(b);

// make sure we get a singleton instance of each service
const PROVIDERS = [{
  provide: BluetoothCore,
  useFactory: bleCore,
  deps: [BrowserWebBluetooth, ConsoleLoggerService]
}, {
  provide: BleService,
  useFactory: bleService,
  deps: [BluetoothCore]
}];

@Component({
  selector: 'app-heartrate',
  templateUrl: './heartrate.component.html',
  styleUrls: ['./heartrate.component.scss']
})
export class HeartrateComponent implements OnDestroy {
  // 0 - No modals
  // 1 - Asks for linking or start w/o hr
  // 2 - Profile info needed
  // 3 - Asks for putting on HR Monitor
  // 4 - Informs that BT should be on
  // 5 - Browser is trying to select and connect
  // 6 - HR is properly synchronized
  // 7 - HR error
  public heartRateStep = 1;

  useHR = true;
  devices: any[] = [];
  public peripheralData: any = null;
  public currentHeartRate: number;
  currentDeviceId: string;

  @Input() heartRate: any;
  @Input() userMaxBpm: number;
  @Input() isMobile: boolean;
  @Output() start = new EventEmitter<string>();

  value = null;
  heartRateValue = null;
  heartRatePercentage = null;
  heartRateDevice: BluetoothDevice;
  mode = 'determinate';
  color = 'primary';
  valuesSubscription: Subscription;
  streamSubscription: Subscription;
  deviceSubscription: Subscription;

  constructor(public ble: BLE, private ngZone: NgZone, private bluetoothService: BleService) {
    bluetoothService.config({
      decoder: (value: DataView) => value.getInt8(1),
      service: 'heart_rate',
      characteristic: 'heart_rate_measurement'
    });
  }

  ngOnInit(): void {
    this.getDeviceStatus();
    this.streamSubscription = this.bluetoothService.stream()
      .subscribe({
        next: this.hrUpdateValue.bind(this),
        error: this.hrHasError.bind(this)
      });
  }

  ngOnDestroy(): void {
    this.ble.disconnect(this.currentDeviceId);
    this.ble.stopStateNotifications();

    this.valuesSubscription.unsubscribe();
    this.deviceSubscription.unsubscribe();
    this.streamSubscription.unsubscribe();
  }

  public scan() {
    this.devices = [];

    if (this.isMobile) {
      this.ble.scan([HEART_RATE_SERVICE_UUID], 15).subscribe(
        device => this.onDeviceDiscovered(device)
      );
    } else {
      this.requestValue();
    }
  }
  private onDeviceDiscovered(device) {
    console.log('BLE-Discovered' + JSON.stringify(device, null, 2));

    this.devices.push(device)
    console.log('BLE-device added: ' + device.id)
  }

  public connectBLE(deviceId: string) {
    this.heartRateStep = 5.1;
    this.ble.autoConnect(deviceId, this.onConnected.bind(this), this.onDisconnected.bind(this));
  }

  private onConnected(peripheralData) {
    // onConnect
    console.log('BLE-Connected');
    this.ngZone.run(() => {
      this.peripheralData = peripheralData;
    });
    console.log('BLE-peripheralData: ' + JSON.stringify(peripheralData));
    this.startHeartRateMonitoring(peripheralData.id);
    this.currentDeviceId = peripheralData.id;

    this.heartRateStep = 6;
  }

  private onDisconnected(peripheralData) {

  }

  public startHeartRateMonitoring(deviceId: string) {
    this.ble.startNotification(deviceId, HEART_RATE_SERVICE_UUID, HEART_RATE_MEASUREMENT_CHARACTERISTIC).subscribe(notificationData => {
      const data = new Uint8Array(notificationData[0]);
      this.ngZone.run(() => {
        this.currentHeartRate = data[1];
        this.heartRate.value = this.currentHeartRate;
      });
    }, function (error) {
    });
  }

  public nextHeartRateStep() { // Generic next step
    this.heartRateStep++;
  }

  public startLinkProcess() {
    if (this.userMaxBpm > 0) {
      this.heartRateStep = 3; // Goes to Put HR monitor
    } else {
      this.heartRateStep = 2; // Show profile Alert modal
    }
  }

  public goToMyProfile() {
    // make something
  }

  public startWithoutHR() {
    this.useHR = false;
    this.startWorkout();
  }

  public startBLEConnection() {
    this.scan();
    if (this.isMobile) {
      this.heartRateStep = 5;
    } else {
      this.heartRateStep = 5.5;
    }
  }

  public heartRateConnection(status: boolean) {
    if (status) {
      this.heartRateStep = 6;
    } else {
      this.heartRateStep = 7;
    }
  }

  public retryHRConnection() {
    this.startLinkProcess();
  }

  public startWorkout() {
    this.heartRateStep = 0;
    this.start.emit();
  }

  ///////////////////////////////
  /////// Desktop service ///////
  ///////////////////////////////

  getDeviceStatus() {
    this.deviceSubscription = this.bluetoothService.getDevice()
      .subscribe({
        next: this.deviceSelected.bind(this),
        error: this.hrHasError.bind(this),
      });
  }

  deviceSelected(device: BluetoothDevice) {
    this.heartRateDevice = device;

    if (this.heartRateStep == 5.5) {
      this.heartRateStep = 6;
    }
  }

  requestValue() {
    this.valuesSubscription = this.bluetoothService.value()
      .subscribe(data => {
      }, error => this.hrHasError.bind(this));
  }

  hrUpdateValue(value: number) {
    // Corrección rápida cuando pasa de HR 128 a -128 en lugar de 129
    if (value < 0) {
      value += 256;
    };
    this.currentHeartRate = value;
    this.heartRate.value = this.currentHeartRate;

  }

  hrHasError(error: string) {
    console.log(error);
  }

  disconnect() {
    this.bluetoothService.disconnectDevice();
    this.deviceSubscription.unsubscribe();
    this.valuesSubscription.unsubscribe();
  }



}
