import { AuthGuard } from './guards/auth-guard';
import { LoginComponent } from './components/login/login.component';
import { PlayerComponent } from './components/player/player.component';
import { WorkoutResolver } from './resolvers/workout.resolver';
import { WorkoutComponent } from './components/workout/workout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { GalleryNewResolver, GalleryByProgramResolver } from './resolvers/gallery.resolver';
import { MaterialListResolver, LevelListResolver } from './resolvers/global.resolver';
import { GalleryPopularResolver } from './resolvers/gallery.resolver';
import { WorkoutsComponent } from './components/workouts/workouts.component';
import { constants } from '../assets/constants';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {
      galleriesNew: GalleryNewResolver,
      galleriesPopular: GalleryPopularResolver,
    },
     canActivate: [AuthGuard],
    data: { role: [constants.roles.ph_user], showHeader: true }
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {      
    },
    data: { showHeader: false }
  },
  {
    path: 'workouts',
    component: WorkoutsComponent,
    resolve: {
      workoutGalleries: GalleryByProgramResolver,
      materialList: MaterialListResolver,
      levelList: LevelListResolver
    },
     canActivate: [AuthGuard],
    data: { galleryType: 1, role: [constants.roles.ph_user], showHeader: true }
  },
  {
    path: 'workout/:id',
    component: WorkoutComponent,
    resolve: {
      workout: WorkoutResolver
    },
     canActivate: [AuthGuard],
    data: { role: [constants.roles.ph_user], showHeader: true }
  },
  // {
  //   path: 'player/:id',
  //   component: PlayerComponent,
  //   resolve: {
  //     workout: WorkoutResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: {}
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
