import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Gallery } from 'src/app/interfaces/gallery';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { constants } from '../../../../assets/constants';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-bigslider',
  templateUrl: './bigslider.component.html',
  styleUrls: ['./bigslider.component.scss']
})
export class BigsliderComponent implements OnInit {

  @Input() gallery: Gallery;
  public urlMedia;

  swiperOptions: SwiperConfigInterface;

  public galleryRandomId: string;

  // customOptions: OwlOptions = {
  //   loop: false,
  //   margin: 20,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: true,
  //   navSpeed: 700,
  //   navText: ['<div class="nav-btn prev-slide"><span class="mdi mdi-chevron-left"></span></div>', '<div class="nav-btn next-slide"><span class="mdi mdi-chevron-right"></span></div>'],
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     400: {
  //       items: 1
  //     },
  //     740: {
  //       items: 1
  //     },
  //     940: {
  //       items: 1
  //     },
  //     1280: {
  //       items: 1,
  //     }
  //   },
  //   nav: true
  // };

  constructor(private router: Router, public globalService: GlobalService) {
    this.urlMedia = constants.urlMedia;

    // Creamos IDs random para que los botones de navegacion sean unicos en cada gallery
    this.galleryRandomId = Math.round(Math.random() * 1000000000).toString();

    this.swiperOptions = {
      direction: 'horizontal',
      spaceBetween: 10,
      slidesPerView: 1,
      navigation: {
        prevEl: '#prev-slide-' + this.galleryRandomId,
        nextEl: '#next-slide-' + this.galleryRandomId,
      },
      breakpoints: {
        // when window width is >= 480px
        960: {
          slidesPerView: 1
        },
        1600: {
          slidesPerView: 1
        }
      },
      pagination: {
        el: '#pagination-' + this.galleryRandomId,
        clickable: true,
      },
    };
  }

  ngOnInit(): void {
  }

  public openElement(item: number) {
    this.router.navigateByUrl('workout/' + item);
  }

  public isNew(itemDate: string) {
    const date = new Date(itemDate);
    const now = new Date();
    const dateComp = new Date(now.getTime() - 1000 * 60 * 60 * 24 * constants.newSessionDaysAgoSlider);

    if (date.getTime() > dateComp.getTime()) {
      return true;
    } else {
      return false;
    }
  }

}
