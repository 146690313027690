import { WorkoutService } from './../services/workout.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class WorkoutResolver implements Resolve<Observable<string>> {

    constructor(private workoutService: WorkoutService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.workoutService.getWorkout(route.paramMap.get('id')).pipe(catchError(() => {
            return of('error');
        }));
    }

    // if (route.paramMap.get('id') !== 'new') {
    //     return this.formManagerService.getForm(route.paramMap.get('id')).catch(() => {
    //         return Observable.of('error');
    //     });
    // } else {
    //     return Observable.of('new');
    // }
}