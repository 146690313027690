import { Component, OnInit } from '@angular/core';
import { Gallery } from 'src/app/interfaces/gallery';
import { ActivatedRoute } from '@angular/router';
import { WorkoutMaterial, WorkoutLevel } from './../../interfaces/workout';
import { constants } from './../../../assets/constants';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';

@Component({
  selector: 'app-workouts',
  templateUrl: './workouts.component.html',
  styleUrls: ['./workouts.component.scss']
})
export class WorkoutsComponent implements OnInit {

  workoutGalleries: Gallery[];
  workoutGalleriesFiltered: Gallery[];

  materialList: WorkoutMaterial[];
  levelList: WorkoutLevel[];

  public breadcrumb: Breadcrumb[];

  constructor(private route: ActivatedRoute) {
    this.workoutGalleries = this.route.snapshot.data.workoutGalleries.galleries;
    this.materialList = this.route.snapshot.data.materialList;
    this.levelList = this.route.snapshot.data.levelList;
    this.workoutGalleriesFiltered = [];
    // this.workoutGalleriesFiltered = clone(this.workoutGalleries);

    this.addNoMaterial();

    this.breadcrumb = [
      {
        name: 'HOME',
        route: '/',
        translate: true
      },
      {
        name: 'WORKOUTS',
        route: '/workouts',
        translate: true
      }
    ];
  }

  ngOnInit(): void {
  }

  addNoMaterial() {
    this.workoutGalleries.forEach(gallery => {
      gallery.workouts.forEach(workout => {
        if (workout.materials.length === 0) {
          workout.materials.push(constants.nomaterial);
        }
      })
    })
  }

}
