import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventsService {

  private eventSource = new Subject<string>();

  event$ = this.eventSource.asObservable();

  public doEvent(params) {
    this.eventSource.next(params);
  }

}


// USO:

// LISTENER
/*
constructor(events: EventsService){
this.events.event$.subscribe((params: any) => {
// Ejemplo:
  if(params.key === 'sidebar'){
    if(params.value === 'toggle'){
      this.sidebartoggle();
    }
  }
});
}
*/

// ORIGEN DE EVENTO
/*
this.events.doEvent({key: 'sidebar', value: 'toggle'});
*/
