import { Component, OnInit, Input } from '@angular/core';

// import { OwlOptions } from 'ngx-owl-carousel-o';
import { constants } from '../../../../assets/constants';
import { Router } from '@angular/router';
import { Workout } from 'src/app/interfaces/workout';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-exercisegallery',
  templateUrl: './exercisegallery.component.html',
  styleUrls: ['./exercisegallery.component.scss']
})
export class ExerciseGalleryComponent implements OnInit {

  @Input() workout: Workout;

  public itemList: any[];

  swiperOptions: SwiperConfigInterface;

  public galleryRandomId: string;

  // customOptions: OwlOptions = {
  //   loop: false,
  //   margin: 20,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: true,
  //   navSpeed: 700,
  //   navText: ['<div class="nav-btn prev-slide"><span class="mdi mdi-chevron-left"></span></div>', '<div class="nav-btn next-slide"><span class="mdi mdi-chevron-right"></span></div>'],
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     400: {
  //       items: 2
  //     },
  //     740: {
  //       items: 3
  //     },
  //     940: {
  //       items: 4
  //     },
  //     1280: {
  //       items: 4,
  //     }
  //   },
  //   nav: true
  // };

  constructor(private router: Router) {
    this.itemList = [];

    // Creamos IDs random para que los botones de navegacion sean unicos en cada gallery
    this.galleryRandomId = Math.round(Math.random() * 1000000000).toString();

    this.swiperOptions = {
      direction: 'horizontal',
      spaceBetween: 10,
      slidesPerView: 1.5,
      navigation: {
        prevEl: '#prev-slide-' + this.galleryRandomId,
        nextEl: '#next-slide-' + this.galleryRandomId,
      },
      breakpoints: {
        // when window width is >= 480px
        960: {
          slidesPerView: 2.5
        },
        1600: {
          slidesPerView: 3.5
        }
      },
      pagination: {
        el: '#pagination-' + this.galleryRandomId,
        clickable: true,
      },
    };
  }

  ngOnInit() {
    this.createItemList();
  }

  private createItemList() {
    this.workout.playerSequence.forEach(block => {
      if (block.type === 1) {
        const itemIndex = this.itemList.findIndex(item => item.id === block.item.id);
        if (itemIndex === -1) {
          this.itemList.push(block.item);
        }
      }
    })
  }

}
