<mat-toolbar class="header" *ngIf="showHeader">
    <mat-toolbar-row class="header-toolbar">
        <!-- DESKTOP -->
        <!-- <div class="inner-container" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center" *ngIf="!globalService.isMobile">
            <div class="header-logo cursor-pointer" routerLink="/">
                <img src="/assets/img/logo_home.png" alt="PRAMA Home Fitness">
            </div>

            <span class="mat-spacer"></span>

            <button mat-icon-button aria-label="Mein Menu" (click)="toggleSidemenu()">                
                <img src="/assets/img/icon_menu_toggle.png" alt="Menu">
            </button>
        </div> -->

        <div class="inner-container" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign.xs="center center" fxLayoutAlign="space-between center">
            <div class="header-logo cursor-pointer text-center" routerLink="/" fxFlexOrder.xs="1" fxFlexOrder="0">
                <img src="/assets/img/logo_home.png" alt="PRAMA Home Fitness">
            </div>

            <!-- <span class="mat-spacer"></span> -->

            <button class="mainmenu-button" mat-icon-button aria-label="Main Menu" (click)="toggleSidemenu()" fxFlexOrder.xs="0" fxFlexOrder="1">
                <img src="/assets/img/icon_menu_toggle.png" alt="Menu" *ngIf="!globalService.sideMenuEnabled">
                <img src="/assets/img/icon_menu_close.png" alt="Menu" *ngIf="globalService.sideMenuEnabled">
            </button>
        </div>

        <!-- MOBILE -->
        <!-- <div class="inner-container mobile" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center" *ngIf="globalService.isMobile">
            <button mat-icon-button aria-label="Mein Menu" (click)="toggleSidemenu()" class="mainmenu-button">                
                <img src="/assets/img/icon_menu_toggle.png" alt="Menu">
            </button>

            <span class="mat-spacer"></span>

            <div class="header-logo cursor-pointer" routerLink="/">
                <img src="/assets/img/logo_home.png" alt="PRAMA Home Fitness">
            </div>
        </div>  -->
    </mat-toolbar-row>
</mat-toolbar>