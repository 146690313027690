import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SidemenuComponent } from '../sidemenu/sidemenu.component';
import { EventsService } from 'src/app/services/events.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public logged: boolean;

  public showHeader: boolean;

  constructor(private events: EventsService,
    private authService: AuthService,
    public globalService: GlobalService,
    public route: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    this.checkIsLogged();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = this.route.root.firstChild.snapshot.data['showHeader']
      }
    });
  }

  public toggleSidemenu() {
    this.events.doEvent({ key: 'sidemenu', value: 'toggle' });
  }

  closeSession() {
    this.authService.closeSession().subscribe(resp => {
      //this.router.navigate(['/login']);
    });
    localStorage.clear();
  }

  private checkIsLogged() {
    this.authService.validateOrRefreshToken().subscribe(
      result => {
        this.logged = result.valid;
      });
  }

}
