import { GlobalService } from './../services/global.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class MaterialListResolver implements Resolve<Observable<string>> {

    constructor(private globalService: GlobalService) { }

    resolve() {
        return this.globalService.getMaterials().pipe(catchError(() => {
            return of('error');
        }));
    }
}
@Injectable()
export class LevelListResolver implements Resolve<Observable<string>> {

    constructor(private globalService: GlobalService) { }

    resolve() {
        return this.globalService.getLevels().pipe(catchError(() => {
            return of('error');
        }));
    }
}

