<mat-toolbar class="footer">
  <mat-toolbar-row class="pv-2">
    <div class="inner-container" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="space-between start">
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">

        <h5 class="mb-1">{{ 'FOOTER.TITLE_1' | translate}}</h5>
        <div class="footer-text">
          <p>{{ 'FOOTER.LINE_1' | translate}}</p>
          <p>{{ 'FOOTER.LINE_2' | translate}}</p>
          <p>{{ 'FOOTER.LINE_3' | translate}}</p>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
          <div class="footer-text">
            {{ 'FOOTER.SOCIAL' | translate}}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
          <div class="footer-text">
            <a [href]="rrss.facebook" target="_blank"><span class="mdi mdi-facebook social-icon mr-1"></span></a>
            <a [href]="rrss.instagram" target="_blank"><span class="mdi mdi-instagram social-icon mr-1"></span></a>
            <a [href]="rrss.twitter" target="_blank"><span class="mdi mdi-twitter social-icon mr-1"></span></a>
          </div>
        </div>
      </div>      
    </div>
  </mat-toolbar-row>
</mat-toolbar>