import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Plugins,
  StatusBarStyle,
} from '@capacitor/core';

const { App } = Plugins;

const { StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pramahome';
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    // StatusBar.hide();

    const safeAreaInsets = require('safe-area-insets');
    // console.log('safeAreaInsets.support', safeAreaInsets.support)
    // console.log('safe-area-inset-top', safeAreaInsets.top)
    // console.log('safe-area-inset-left', safeAreaInsets.left)
    // console.log('safe-area-inset-right', safeAreaInsets.right)
    // console.log('safe-area-inset-bottom', safeAreaInsets.bottom)
  }

  onActivate(event) {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  ngOnInit(): void {
    // Prevenimos botón Back
    App.addListener('backButton', () => {
      
    });
    
  }
}
