import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { constants } from '../../assets/constants';

@Injectable({
  providedIn: 'root'
})
export class WorkoutService {

  private urlGetWorkout = constants.urlAPI + constants.endpoints.workout.get;

  constructor(public http: HttpClient) { }

  public getWorkout(workoutId: any): Observable<any> {
    return this.http.get(this.urlGetWorkout.replace(':id', workoutId.toString()));


    return of(
      {
        id: 51,
        name: null,
        image: 'https://creator.pramacloud.com/public/homefitness-workout/25.jpg',
        description: null,
        longDescription: null,
        duration: 1180,
        date: '2020-09-10T08:50:17',
        level: {
            id: 1,
            name: 'Beginner'
        },
        mode: {
            id: 3,
            name: 'SuperSet'
        },
        type: {
            id: 1,
            name: 'Workout'
        },
        idUser: 0,
        program: {
            id: 34,
            name: 'Energy',
            gym: 1
        },
        materials: [
            {
                id: 13,
                name: 'Fitball',
                image: 'fitball.png'
            }
        ],
        playerSequence: [
            {
                id: 5597,
                item: {
                    id: 1372,
                    behaviour: 1,
                    text: null,
                    image: null,
                    idPosition: 0
                },
                type: 3,
                initTime: 0,
                duration: 10,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Warm up',
                        duration: 10,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 1,
                nExercise: '',
                nRound: ''
            },
            {
                id: 5598,
                item: {
                    id: 39,
                    name: 'Lunge side to side',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2418.png',
                    gif: 'https://creator.pramacloud.com/public/exercises/2418.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2418.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:49',
                    pramaId: null
                },
                type: 1,
                initTime: 10,
                duration: 60,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Warm up',
                        duration: 60,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 1,
                nExercise: '1',
                nRound: '1'
            },
            {
                id: 5599,
                item: {
                    id: 40,
                    name: 'Squat in squat out',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2421.png',
                    gif: 'https://creator.pramacloud.com/public/exercises/2421.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2421.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:49',
                    pramaId: null
                },
                type: 1,
                initTime: 70,
                duration: 60,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Warm up',
                        duration: 60,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 1,
                nExercise: '2',
                nRound: '1'
            },
            {
                id: 5600,
                item: {
                    id: 41,
                    name: 'Plank to jack',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2432.png',
                    gif: 'https://creator.pramacloud.com/public/exercises/2432.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2432.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:50',
                    pramaId: null
                },
                type: 1,
                initTime: 130,
                duration: 60,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Warm up',
                        duration: 60,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 1,
                nExercise: '3',
                nRound: '1'
            },
            {
                id: 5601,
                item: {
                    id: 1373,
                    behaviour: 1,
                    text: null,
                    image: null,
                    idPosition: 0
                },
                type: 3,
                initTime: 190,
                duration: 10,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 10,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '',
                nRound: ''
            },
            {
                id: 5602,
                item: {
                    id: 43,
                    name: 'squat jumps',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2121.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2121.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2121.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:51',
                    pramaId: null
                },
                type: 1,
                initTime: 200,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1A',
                nRound: '1'
            },
            {
                id: 5603,
                item: {
                    id: 1374,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 245,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1A',
                nRound: '1'
            },
            {
                id: 5604,
                item: {
                    id: 44,
                    name: 'Rock back push up',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2403.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2403.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2403.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 265,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1B',
                nRound: '1'
            },
            {
                id: 5605,
                item: {
                    id: 1375,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 310,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1B',
                nRound: '1'
            },
            {
                id: 5606,
                item: {
                    id: 43,
                    name: 'squat jumps',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2121.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2121.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2121.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:51',
                    pramaId: null
                },
                type: 1,
                initTime: 330,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2A',
                nRound: '1'
            },
            {
                id: 5607,
                item: {
                    id: 1376,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 365,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2A',
                nRound: '1'
            },
            {
                id: 5608,
                item: {
                    id: 44,
                    name: 'Rock back push up',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2403.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2403.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2403.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 385,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2B',
                nRound: '1'
            },
            {
                id: 5609,
                item: {
                    id: 1377,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 420,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2B',
                nRound: '1'
            },
            {
                id: 5610,
                item: {
                    id: 43,
                    name: 'squat jumps',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2121.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2121.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2121.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:51',
                    pramaId: null
                },
                type: 1,
                initTime: 440,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '3A',
                nRound: '1'
            },
            {
                id: 5611,
                item: {
                    id: 1378,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 470,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '3A',
                nRound: '1'
            },
            {
                id: 5612,
                item: {
                    id: 44,
                    name: 'Rock back push up',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2403.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2403.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2403.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 490,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '3B',
                nRound: '1'
            },
            {
                id: 5613,
                item: {
                    id: 1379,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 520,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '3B',
                nRound: '1'
            },
            {
                id: 5614,
                item: {
                    id: 45,
                    name: 'Frog sit up',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2406.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2406.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2406.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 550,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1A',
                nRound: '2'
            },
            {
                id: 5615,
                item: {
                    id: 1380,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 595,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1A',
                nRound: '2'
            },
            {
                id: 5616,
                item: {
                    id: 46,
                    name: 'plank to squat',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2409.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2409.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2409.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 615,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1B',
                nRound: '2'
            },
            {
                id: 5617,
                item: {
                    id: 1381,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 660,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '1B',
                nRound: '2'
            },
            {
                id: 5618,
                item: {
                    id: 45,
                    name: 'Frog sit up',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2406.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2406.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2406.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 680,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2A',
                nRound: '2'
            },
            {
                id: 5619,
                item: {
                    id: 1382,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 715,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2A',
                nRound: '2'
            },
            {
                id: 5620,
                item: {
                    id: 46,
                    name: 'plank to squat',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2409.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2409.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2409.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 735,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2B',
                nRound: '2'
            },
            {
                id: 5621,
                item: {
                    id: 1383,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 770,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '2B',
                nRound: '2'
            },
            {
                id: 5622,
                item: {
                    id: 45,
                    name: 'Frog sit up',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2406.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2406.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2406.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 790,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '3A',
                nRound: '2'
            },
            {
                id: 5623,
                item: {
                    id: 1384,
                    behaviour: 0,
                    text: 'Rest time',
                    image: 'https://creator.pramacloud.com/public/rest_image.png',
                    idPosition: 1
                },
                type: 3,
                initTime: 820,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 20,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '3A',
                nRound: '2'
            },
            {
                id: 5624,
                item: {
                    id: 46,
                    name: 'plank to squat',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/2409.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/2409.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/2409.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:52',
                    pramaId: null
                },
                type: 1,
                initTime: 840,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 3,
                totalRounds: 2,
                nExercise: '3B',
                nRound: '2'
            },
            {
                id: 5625,
                item: {
                    id: 1385,
                    behaviour: 1,
                    text: null,
                    image: null,
                    idPosition: 0
                },
                type: 3,
                initTime: 870,
                duration: 10,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Cooldown',
                        duration: 10,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 1,
                totalRounds: 1,
                nExercise: '',
                nRound: ''
            },
            {
                id: 5626,
                item: {
                    id: 42,
                    name: 'Side lunge with back extension',
                    description: '',
                    image: 'https://creator.pramacloud.com/public/exercises/716.jpg',
                    gif: 'https://creator.pramacloud.com/public/exercises/716.gif',
                    video: 'https://creator.pramacloud.com/public/exercises/716.mp4',
                    status: 1,
                    creationDate: '2020-06-23T16:22:51',
                    pramaId: null
                },
                type: 1,
                initTime: 880,
                duration: 300,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Cooldown',
                        duration: 300,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [
                    {
                        id: 13,
                        name: 'Fitball',
                        image: 'fitball.png'
                    }
                ],
                totalExercise: 1,
                totalRounds: 1,
                nExercise: '1',
                nRound: '1'
            }
        ],
        musicSequence: [
            {
                id: 5627,
                item: null,
                type: 4,
                initTime: 0,
                duration: 10,
                order: 0,
                tags: [],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '',
                nRound: ''
            },
            {
                id: 5628,
                item: null,
                type: 4,
                initTime: 10,
                duration: 60,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Warm up',
                        duration: 60,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1',
                nRound: '1'
            },
            {
                id: 5629,
                item: null,
                type: 4,
                initTime: 70,
                duration: 60,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Warm up',
                        duration: 60,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2',
                nRound: '1'
            },
            {
                id: 5630,
                item: null,
                type: 4,
                initTime: 130,
                duration: 60,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Warm up',
                        duration: 60,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3',
                nRound: '1'
            },
            {
                id: 5631,
                item: null,
                type: 4,
                initTime: 190,
                duration: 10,
                order: 0,
                tags: [],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '',
                nRound: ''
            },
            {
                id: 5632,
                item: null,
                type: 4,
                initTime: 200,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1A',
                nRound: '1'
            },
            {
                id: 5633,
                item: null,
                type: 4,
                initTime: 245,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1A',
                nRound: '1'
            },
            {
                id: 5634,
                item: null,
                type: 4,
                initTime: 265,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1B',
                nRound: '1'
            },
            {
                id: 5635,
                item: null,
                type: 4,
                initTime: 310,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1B',
                nRound: '1'
            },
            {
                id: 5636,
                item: null,
                type: 4,
                initTime: 330,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2A',
                nRound: '1'
            },
            {
                id: 5637,
                item: null,
                type: 4,
                initTime: 365,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2A',
                nRound: '1'
            },
            {
                id: 5638,
                item: null,
                type: 4,
                initTime: 385,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2B',
                nRound: '1'
            },
            {
                id: 5639,
                item: null,
                type: 4,
                initTime: 420,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2B',
                nRound: '1'
            },
            {
                id: 5640,
                item: null,
                type: 4,
                initTime: 440,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3A',
                nRound: '1'
            },
            {
                id: 5641,
                item: null,
                type: 4,
                initTime: 470,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3A',
                nRound: '1'
            },
            {
                id: 5642,
                item: null,
                type: 4,
                initTime: 490,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3B',
                nRound: '1'
            },
            {
                id: 5643,
                item: null,
                type: 4,
                initTime: 520,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 0,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3B',
                nRound: '1'
            },
            {
                id: 5644,
                item: null,
                type: 4,
                initTime: 550,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1A',
                nRound: '2'
            },
            {
                id: 5645,
                item: null,
                type: 4,
                initTime: 595,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1A',
                nRound: '2'
            },
            {
                id: 5646,
                item: null,
                type: 4,
                initTime: 615,
                duration: 45,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1B',
                nRound: '2'
            },
            {
                id: 5647,
                item: null,
                type: 4,
                initTime: 660,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 45,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1B',
                nRound: '2'
            },
            {
                id: 5648,
                item: null,
                type: 4,
                initTime: 680,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2A',
                nRound: '2'
            },
            {
                id: 5649,
                item: null,
                type: 4,
                initTime: 715,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2A',
                nRound: '2'
            },
            {
                id: 5650,
                item: null,
                type: 4,
                initTime: 735,
                duration: 35,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2B',
                nRound: '2'
            },
            {
                id: 5651,
                item: null,
                type: 4,
                initTime: 770,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 35,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '2B',
                nRound: '2'
            },
            {
                id: 5652,
                item: null,
                type: 4,
                initTime: 790,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3A',
                nRound: '2'
            },
            {
                id: 5653,
                item: null,
                type: 4,
                initTime: 820,
                duration: 20,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3A',
                nRound: '2'
            },
            {
                id: 5654,
                item: null,
                type: 4,
                initTime: 840,
                duration: 30,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Workout',
                        duration: 30,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '3B',
                nRound: '2'
            },
            {
                id: 5655,
                item: null,
                type: 4,
                initTime: 870,
                duration: 10,
                order: 0,
                tags: [],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '',
                nRound: ''
            },
            {
                id: 5656,
                item: null,
                type: 4,
                initTime: 880,
                duration: 300,
                order: 0,
                tags: [
                    {
                        idBlock: 0,
                        name: 'Cooldown',
                        duration: 300,
                        order: 0,
                        phase: 0
                    }
                ],
                materials: [],
                totalExercise: 0,
                totalRounds: 0,
                nExercise: '1',
                nRound: '1'
            }
        ],
        status: 1,
        lastUpdate: '0001-01-01T00:00:00',
        activeFrom: '2020-08-17T11:34:04',
        activeUntil: '2120-08-17T11:34:04',
        pramaId: 25,
        workoutType: 3
    }
    );
  }
}
