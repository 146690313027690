import { Component, OnInit, Input } from '@angular/core';
import { Gallery } from 'src/app/interfaces/gallery';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { constants } from '../../../../assets/constants';
import { Router } from '@angular/router';
import { Workout } from 'src/app/interfaces/workout';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @Input() gallery: Gallery;
  public urlMedia;

  swiperOptions: SwiperConfigInterface;

  public galleryRandomId: string;


  constructor(private router: Router) {
    this.urlMedia = constants.urlMedia;

    // Creamos IDs random para que los botones de navegacion sean unicos en cada gallery
    this.galleryRandomId = Math.round(Math.random() * 1000000000).toString();

    this.swiperOptions = {
      direction: 'horizontal',
      spaceBetween: 10,
      slidesPerView: 1.5,
      navigation: {
        prevEl: '#prev-slide-' + this.galleryRandomId,
        nextEl: '#next-slide-' + this.galleryRandomId,
      },
      breakpoints: {
        // when window width is >= 480px
        960: {
          slidesPerView: 2.5
        },
        1600: {
          slidesPerView: 3.5
        }
      },
      pagination: {
        el: '#pagination-' + this.galleryRandomId,
        clickable: true,
      },
    };
  }

  ngOnInit() {
  }

  public openElement(item: number) {
    this.router.navigateByUrl('workout/' + item);
  }

  public isNew(itemDate: string) {
    const date = new Date(itemDate);
    const now = new Date();
    const dateComp = new Date(now.getTime() - 1000 * 60 * 60 * 24 * constants.newSessionDaysAgoGallery);

    if (date.getTime() > dateComp.getTime()) {
      return true;
    } else {
      return false;
    }
  }

}
