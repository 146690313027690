import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { constants } from './../../../../assets/constants';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  public menuEnabled: boolean;
  public userName: string;
  public userImage: string;

  constructor(private events: EventsService, public authService: AuthService, public globalService: GlobalService) {
    this.events.event$.subscribe((params: any) => {
      if (params.key === 'sidemenu') {
        if (params.value === 'toggle') {
          this.toggleMenu();
        }
      }
    });

    let sessionInfo = JSON.parse(localStorage.getItem(constants.keySession));
    if (sessionInfo) {
      this.userName = sessionInfo.userName ? sessionInfo.userName : '';
      this.userImage = sessionInfo.userImage ? sessionInfo.userImage : '';
    }
  }

  ngOnInit() {
  }

  public toggleMenu() {
    this.menuEnabled = !this.menuEnabled;
    this.globalService.sideMenuEnabled = this.menuEnabled;
  }
}
