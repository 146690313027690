<div class="login-message-container" fxLayout="row" fxLayoutAlign="center center" fxFill *ngIf="!loginError">
    <div class="login-message">
        <mat-spinner></mat-spinner>
    </div>
</div>
<!-- <div class="login-message-container" fxLayout="row" fxLayoutAlign="center center" fxFill *ngIf="loginError">
    <div class="login-message label-black-medium">
        <span>Login failed!</span>
    </div>
</div> -->
<div class="login-container" fxLayout="column" fxLayoutAlign="center stretch" fxFill *ngIf="loginError">
    <div fxLayout="column" fxLayoutAlign="center center">
        <h1><img src="/assets/img/logo_home.png" alt="PRAMA Home Fitness" class="login-logo"></h1>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" fxFlex="100"
            class="w-100">
            <div fxLayout="column" fxLayoutAlign="center end" fxLayoutAlign.lt-lg="start center" fxLayoutGap="20px"
                fxFlex="100" fxFlexOrder="1" fxFlexOrder.lt-lg="2">
                <div class="login-text-container">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div class="title-yellowbg">{{'LOGIN.LOGIN_TEXT_1' | translate}}</div>
                    </div>
                    <div class="title-blackbg">{{'LOGIN.LOGIN_TEXT_2' | translate}}</div>
                    <div class="desc-blackbg">{{'LOGIN.LOGIN_TEXT_3' | translate}}</div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.lt-lg="start center" fxLayoutGap="20px"
                fxFlex="100" fxFlexOrder="2" fxFlexOrder.lt-lg="1">
                <div class="login-form-container" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <!-- <div class="section-title-big">LOGIN</div> -->
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Username / Email</mat-label>
                        <input matInput type="text" [(ngModel)]="loginForm.user" class="filter-text"
                            (click)="hideErrors()">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" [(ngModel)]="loginForm.password" class="filter-text"
                            (click)="hideErrors()">
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" fxFlex="100" class="w-100">
                        <div class="w-100">
                            <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
                            <div class="login-error text-right" *ngIf="showLoginError">
                                {{'LOGIN.ERROR_RESPONSE' | translate}}</div>
                        </div>
                        <div>
                            <button mat-flat-button class="black-button-small" (click)="doLoginForm()"
                                [disabled]="!loginForm.user || !loginForm.password">Enter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>