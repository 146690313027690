import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totime'
})
export class ToTime implements PipeTransform {

  transform(value: any, args?: any): any {
    const minutes = Math.floor(value / 60);
    const seconds = ("0" + value % 60).slice(-2);    
    return minutes + ':' + seconds;
  }

}
