<div class="gallery">
    <h2 class="gallery-name">
        {{gallery.name}}
    </h2>
    <!-- <owl-carousel-o [options]="customOptions" fxLayout="row" fxLayoutGap="20px">
        <ng-container *ngFor="let item of gallery.workouts">
            <ng-template carouselSlide [id]="item.id.toString()">
                <div class="gallery-card" fxLayout="column" fxLayoutAlign="space-between stretch" (click)="openElement(item.id)">

                    <img [src]="item.image">

                    <div class="gallery-card-info pos-rel" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch" fxFlex>
                        <div class="gallery-card-info" fxLayout="column" fxLayoutAlign="start start" fxFlex>
                            <div class="gallery-card-info" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="30px" class="w-100 h-100">
                                <div class="label-black-medium session-type-label" fxFlexOrder="1">{{item.type.name}}</div>
                                <div class="gallery-card-title slider-card-title" fxFlexOrder="2" fxFlexOrder.xs="3">
                                    {{item.name}}
                                </div>
                                <div class="gallery-card-specs" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlexOrder="3" fxFlexOrder.xs="2">
                                    <div class="label-black-small" *ngIf="item.program">{{item.program.name}}</div>
                                    <div class="label-black-small" *ngIf="item.level">{{item.level.name}}</div>
                                    <div class="label-black-small" *ngIf="item.mode">{{item.mode.name}}</div>                                    
                                </div>
                            </div>
                            <div class="slider-card-description" [ngClass.xs]="'w-100'" fxFlex *ngIf="item.description">
                                <p class="mt-0 mb-0" [ngClass.xs]="'text-center'">{{item.description}}</p>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="space-between center">
                            <div class="slider-card-time-big pb-1" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                                <span class="mdi mdi-clock-outline"></span>
                                <span>{{item.duration | tominutes}} {{'GALLERY.TIME_MINUTES' | translate}}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o> -->
    <div class="gallery" *ngIf="gallery.workouts.length > 0">
        <div [id]="'prev-slide-' + galleryRandomId" class="nav-btn prev-slide"><span
                class="mdi mdi-chevron-left"></span></div>
        <swiper fxFlex="100" [config]="swiperOptions">
            <div *ngFor="let item of gallery.workouts" class="swiper-slide">
                <!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            {{item.name}}
          </div> -->
                <!-- <ng-container carouselSlide [width]="285" [id]="item.id.toString()"> -->
                <div class="gallery-card cursor-pointer" fxLayout="column" fxLayoutAlign="space-between stretch"
                    (click)="openElement(item.id)">

                    <img [src]="item.image">

                    <div class="gallery-card-info pos-rel" fxLayout="row" fxLayout.xs="column"
                        fxLayoutAlign="space-between stretch" fxFlex="100">
                        <div class="gallery-card-info" fxLayout="column" fxLayoutAlign="start start" fxFlex="100">
                            <div class="gallery-card-info" fxLayout="row" fxLayout.xs="column"
                                fxLayoutAlign="start center" fxLayoutGap="30px" class="w-100 h-100">
                                <div class="label-black-medium session-type-label" fxFlexOrder="1">{{item.type.name}}
                                </div>
                                <div class="gallery-card-title slider-card-title" fxFlexOrder="2" fxFlexOrder.xs="3">
                                    {{item.name}}
                                </div>
                                <div class="gallery-card-specs" fxLayoutAlign="center center" fxLayoutGap="5px"
                                    fxFlexOrder="3" fxFlexOrder.xs="2">
                                    <div class="label-black-small" *ngIf="item.program">{{item.program.name}}</div>
                                    <div class="label-black-small" *ngIf="item.level">{{item.level.name}}</div>
                                    <div class="label-black-small" *ngIf="item.mode">{{item.mode.name}}</div>
                                </div>
                            </div>
                            <div class="slider-card-description" [ngClass.xs]="'w-100'" fxFlex="100" *ngIf="item.description">
                                <p class="mt-0 mb-0" [ngClass.xs]="'text-center'">{{item.description}}</p>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="space-between center">
                            <div class="slider-card-time-big pb-1" fxLayout="row" fxLayoutAlign="space-between center"
                                fxLayoutGap="10px">
                                <span class="mdi mdi-clock-outline"></span>
                                <span>{{item.duration | tominutes}} {{'GALLERY.TIME_MINUTES' | translate}}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- </ng-container> -->
            </div>
        </swiper>
        <div [id]="'next-slide-' + galleryRandomId" class="nav-btn next-slide"><span
                class="mdi mdi-chevron-right"></span></div>
    </div>
    <div [id]="'pagination-' + galleryRandomId" class="swiper-paginator text-center mt-1"></div>
</div>