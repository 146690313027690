import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from './../../assets/constants';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private urlGetMaterials = constants.urlAPI + constants.endpoints.global.getMaterials;
  public isMobile: boolean;
  public sideMenuEnabled: boolean = false;

  constructor(public http: HttpClient, private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
  }

  public getMaterials(): Observable<any> {
    return this.http.get(this.urlGetMaterials);
  }

  public getLevels(): Observable<any> {
    return of([
      {
        id: 1,
        name: "Beginner"
      },
      {
        id: 2,
        name: "Medium"
      },
      {
        id: 3,
        name: "Advanced"
      },
    ]);
  }

}