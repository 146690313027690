import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { constants } from '../../assets/constants';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  private urlGetNew = constants.urlAPI + constants.endpoints.gallery.getNew;
  private urlGetPopular = constants.urlAPI + constants.endpoints.gallery.getPopular;
  private urlGetPopularByType = constants.urlAPI + constants.endpoints.gallery.getPopularByType;
  private urlGetByProgram = constants.urlAPI + constants.endpoints.gallery.getByProgram;

  constructor(public http: HttpClient) { }

  public getNew(): Observable<any> {
    return this.http.get(this.urlGetNew);
  }

  public getPopular(): Observable<any> {
    return this.http.get(this.urlGetPopular);
  }

  public getByProgram(galleryType: number): Observable<any> {
    const parameters = {
      type: galleryType.toString()
    };
    return this.http.get(this.urlGetByProgram, { params: parameters });
  }
}

