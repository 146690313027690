<div class="inner-container pos-rel" fxFlex>
    <app-sidemenu></app-sidemenu>

    <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="pb-2">
        <h2 class="section-title">
            <app-breadcrumb [elements]="breadcrumb"></app-breadcrumb>
        </h2>
        <app-filter [galleries]="workoutGalleries" [galleriesFiltered]="workoutGalleriesFiltered"
            [levelList]="levelList"></app-filter>
        <app-gallery [gallery]="gallery" *ngFor="let gallery of workoutGalleriesFiltered"></app-gallery>
    </div>

</div>