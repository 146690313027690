<div class="gallery">
    <!-- <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let item of itemList">
            <ng-template carouselSlide [id]="item.id.toString()">
                <div class="gallery-card" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="gallery-card-image gallery-card-image-exercise" [ngStyle]="{'background-image':'url('+item.gif+')'}">
                        <div class="gallery-card-title-exercise">
                            {{item.name}}
                        </div>
                    </div>                    
                    <div class="overlay-dark"></div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o> -->

    <div [id]="'prev-slide-' + galleryRandomId" class="nav-btn prev-slide"><span class="mdi mdi-chevron-left"></span>
    </div>
    <swiper fxFlex [config]="swiperOptions">
        <div *ngFor="let item of itemList" class="swiper-slide">
            <!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            {{item.name}}
          </div> -->
            <!-- <ng-container carouselSlide [width]="285" [id]="item.id.toString()"> -->
            <div class="gallery-card" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="gallery-card-image gallery-card-image-exercise"
                    [ngStyle]="{'background-image':'url('+item.gif+')'}">
                    <div class="gallery-card-title-exercise">
                        {{item.name}}
                    </div>
                </div>
                <div class="overlay-dark"></div>
            </div>
            <!-- </ng-container> -->
        </div>
    </swiper>
    <div [id]="'next-slide-' + galleryRandomId" class="nav-btn next-slide">
        <span class="mdi mdi-chevron-right"></span>
    </div>

</div>
<div [id]="'pagination-' + galleryRandomId" class="swiper-paginator text-center mt-1"></div>