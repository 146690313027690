<div class="workout-filter-modal">
    <h1 mat-dialog-title class="section-title text-center">{{ 'FILTER.TITLE' | translate }}</h1>
    <div mat-dialog-content>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" [ngClass.gt-xs]="'mt-2'">
            <h4 class="filter-section-title" fxFlexAlign="center">
                {{ 'FILTER.WORKOUT_TIME' | translate }}</h4>
            <!-- <npn-slider [min]="0" [max]="60" (onChange)="onTimeSliderChange($event)" [step]="5"
                [showStepIndicator]="true" [minSelected]="data.filters.minTime" [maxSelected]="data.filters.maxTime"
                fxFlexAlign="center" class="filter-time-slider w-100 mt-3"></npn-slider> -->

            <ng5-slider class="filter-time-slider w-100 mt-3 noanimate" fxFlexAlign="center"
                [(value)]="data.filters.minTime" [(highValue)]="data.filters.maxTime"
                [options]="{floor: 0, ceil: 60, step: 5, hideLimitLabels: true, hidePointerLabels: false}"
                (userChangeEnd)="this.filterChanged()" [manualRefresh]="sliderRefresh">
            </ng5-slider>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="mt-2">
            <h4 class="filter-section-title" fxFlexAlign="center">
                {{ 'FILTER.LEVEL' | translate }}</h4>
            <div fxLayout="column" fxLayoutAlign="space-between center" [ngClass.gt-xs]="'mt-2'">
                <mat-button-toggle-group multiple="true" name="levels" [(ngModel)]="this.data.filters.levels">
                    <mat-button-toggle disableRipple [value]="level" *ngFor="let level of data.levelList"
                        (click)="this.filterChanged()">
                        <div class="button-name"> {{level.name}}</div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="filter-materials mt-2">
            <h4 class="filter-section-title" fxFlexAlign="center">
                {{ 'FILTER.MATERIAL' | translate }}</h4>
            <div fxLayout="column" fxLayoutAlign="space-between center" [ngClass.gt-xs]="'mt-2'">
                <!-- <mat-button-toggle-group multiple="true" name="materials" [(ngModel)]="this.data.filters.materials" -->
                <!-- fxLayout="row wrap" class="materials-list-group"> -->
                <div fxLayout="row wrap" class="materials-list-group">
                    <mat-button-toggle disableRipple *ngFor="let material of data.materialList"
                        class="filter-mat-button" fxFlex="33.3333" fxFlex.xs="50"
                        (change)="this.materialChanged(material)" [name]="'materialToggle' + material.id"
                        [id]="'materialToggle' + material.id" [checked]="isMaterialChecked(material)">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="w-100">
                            <div>
                                <img class="material-image" [src]="urlMaterialImages + material.image">
                            </div>
                            <div class="button-name materials-button">{{material.name}}</div>
                        </div>
                    </mat-button-toggle>
                </div>
                <!-- </mat-button-toggle-group> -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="center stretch" fxLayout="row" class="mt-1 mb-1" fxLayoutGap="10px">
        <button mat-button class="mat-button-modal" (click)="resetFilters()" fxFlex="30">
            <span class="mdi mdi-restore"></span>
            <span class="not-mobile-part">{{'FILTER.RESTORE' | translate }}</span>

        </button>
        <button mat-button class="mat-button-modal" [mat-dialog-close]="data.filters" cdkFocusInitial fxFlex="30">
            <span class="mdi mdi-check"></span>
            <span class="not-mobile-part">{{'FILTER.APPLY_FILTER' | translate }}</span>
        </button>
    </div>
</div>