<div class="inner-container pos-rel" fxFlex>
    <app-sidemenu></app-sidemenu>

    <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="pb-2">
        <app-bigslider [gallery]="galleriesNew[0]"></app-bigslider>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <div class="section-subtitle-big" fxFlex="33.3333" fxFlex.lt-xl="50">{{'HOME.WORKOUTS_LINK_TEXT' | translate}}</div>
            <div fxFlex="33.3333" fxFlex.lt-xl="50" fxLayout="row" fxLayoutAlign="center stretch">
                <button mat-button class="yellow-button" routerLink="/workouts">{{ 'HOME.WORKOUTS_LINK_BUTTON' | translate}}</button>
            </div>
        </div>
        <app-gallery [gallery]="gallery" *ngFor="let gallery of galleriesPopular"></app-gallery>
    </div>

</div>