import { EventsService } from './../../../services/events.service';
import { Workout, WorkoutMaterial, WorkoutLevel } from './../../../interfaces/workout';
import { Component, OnInit, Input, Inject, AfterViewInit, EventEmitter } from '@angular/core';
import { Gallery } from 'src/app/interfaces/gallery';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Filters } from 'src/app/interfaces/filters';
import { assign, cloneDeep, isEqual } from 'lodash';
import { constants } from './../../../../assets/constants';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() galleries: Gallery[];
  @Input() galleriesFiltered: Gallery[];
  // @Input() materialList: WorkoutMaterial[];
  @Input() levelList: WorkoutLevel[];

  public materialList: WorkoutMaterial[];

  public filters: Filters;
  public filtersDefault: Filters = {
    filterText: '',
    minTime: 0,
    maxTime: 60,
    levels: [],
    materials: []
  };

  constructor(public filterModal: MatDialog, private events: EventsService) {
    let localFilters = localStorage.getItem('workouts-filters');
    if (localFilters) {
      this.filters = JSON.parse(localFilters);
    } else {
      this.resetFilters();
    }

    // Capturamos evento para controlar cambio de filtro
    this.events.event$.subscribe((params: any) => {
      if (params.key === 'workout-filter-changed') {
        if (params.value === 'true') {
          this.updateFilter();
        }
      }
    });

    this.materialList = [];
  }

  ngOnInit(): void {
    this.materialList.push(constants.nomaterial);
    this.createMaterialList();
    this.updateFilter();
  }

  private createMaterialList() {
    this.galleries.forEach(gallery => {
      gallery.workouts.forEach(workout => {
        workout.materials.forEach(material => {
          if (this.materialList.findIndex(x => x.id === material.id) === -1) {
            this.materialList.push(material);
          }
        });
      });
    });
  }

  openFilterModal(): void {
    const dialogRef = this.filterModal.open(FilterModalComponent, {
      width: '600px',
      hasBackdrop: true,
      disableClose: true,
      data: {
        filters: this.filters,
        filtersDefault: this.filtersDefault,
        materialList: this.materialList,
        levelList: this.levelList,
        galleries: this.galleries,
        galleriesFiltered: this.galleriesFiltered
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.filters = result;
    });
  }

  public resetFilters() {
    this.filters = {
      filterText: this.filtersDefault.filterText,
      minTime: this.filtersDefault.minTime,
      maxTime: this.filtersDefault.maxTime,
      levels: this.filtersDefault.levels,
      materials: this.filtersDefault.materials
    };
  }

  public isFiltered() {
    // return !isEqual(this.filters, this.filtersDefault);
    return (
      this.filters.filterText !== ''
      || this.filters.levels.length !== 0
      || this.filters.materials.length !== 0
      || this.filters.minTime !== this.filtersDefault.minTime
      || this.filters.maxTime !== this.filtersDefault.maxTime
    );
  }

  public updateFilter() {
    // let filterMaterial;

    console.log(this.filters);

    // Vaciamos galleriesFiltered
    this.galleriesFiltered.length = 0;

    this.galleries.forEach(gallery => {
      // Añadimos galeria a galleriesFiltered
      const newGallery: Gallery = {
        name: gallery.name,
        workouts: []
      };
      this.galleriesFiltered.push(newGallery);

      gallery.workouts.forEach(workout => {

        // Filtrado por cadena
        let stringMatches = 0;
        if (this.filters.filterText && workout.name && workout.name.toLowerCase().includes(this.filters.filterText.toLowerCase())) {
          stringMatches++;
        }

        // Filtrado por tiempo
        //        if (this.filters.minTime !== this.filtersDefault.minTime || this.filters.maxTime !== this.filtersDefault.maxTime) {
        let timeMatches = 0;
        if (workout.duration / 60 >= this.filters.minTime && workout.duration / 60 <= this.filters.maxTime) {
          timeMatches++;
        }
        //        }

        // Filtrado por level
        let levelMatches = 0;
        this.filters.levels.forEach(filteredlevel => {
          if (workout.level.id === filteredlevel.id) {
            levelMatches++;
          }

        });

        // Filtrado por material
        let materialMatches = 0;
        workout.materials.forEach(material => {
          this.filters.materials.forEach(filteredMaterial => {
            if (material.id === filteredMaterial.id) {
              materialMatches++;
            }
          });
        });

        // Sumamos puntuacion de coincidencias de filtros
        workout.orderValue = levelMatches + materialMatches;

        // Si todos los filtros elegidos > 0 o no hay ningún filtro elegido, añadimos workout a ultima galeria
        if (!this.isFiltered() || (
          (this.filters.materials.length === 0 || (this.filters.materials.length > 0 && materialMatches > 0 && materialMatches >= workout.materials.length))
          && (this.filters.levels.length === 0 || (this.filters.levels.length > 0 && levelMatches > 0))
          && (this.filters.filterText === '' || (this.filters.filterText !== '' && stringMatches > 0))
          && (timeMatches > 0)
        )
        ) {
          this.galleriesFiltered[this.galleriesFiltered.length - 1].workouts.push(workout);
        }

      });

      // Si la galeria creada acaba vacia, la eliminamos
      if (this.galleriesFiltered[this.galleriesFiltered.length - 1].workouts.length === 0) {
        this.galleriesFiltered.pop();
      }
    });

    localStorage.setItem('workouts-filters', JSON.stringify(this.filters));
  }

  public updateFilterOLD() {
    if (this.galleries) {

      // Clonamos Galleries a GalleriesFiltered
      this.galleriesFiltered.length = 0;
      assign(this.galleriesFiltered, cloneDeep(this.galleries));

      if (this.isFiltered()) {

        // Eliminamos workouts que no aparezcan por filtro
        this.galleriesFiltered.forEach(gallery => {
          let filterString;
          let filterTime;
          let filterLevel;
          let filterMaterial;

          for (let i = gallery.workouts.length - 1; i >= 0; i--) {
            filterString = -1;
            filterTime = -1;
            filterLevel = -1;
            filterMaterial = -1;


            // Filtrado por cadena
            if (this.filters.filterText !== this.filtersDefault.filterText) {
              filterString = 0;
              if (gallery.workouts[i].name.toLowerCase().includes(this.filters.filterText.toLowerCase())) {
                filterString = 1;
              }
            }

            // Filtrado por tiempo
            if (this.filters.minTime !== this.filtersDefault.minTime || this.filters.maxTime !== this.filtersDefault.maxTime) {
              filterTime = 0;
              if (gallery.workouts[i].duration / 60 >= this.filters.minTime && gallery.workouts[i].duration / 60 <= this.filters.maxTime) {
                filterTime = 1;
              }
            }

            // Filtrado por nivel
            if (!this.objectsAreSame(this.filters.levels, this.filtersDefault.levels)) {
              filterLevel = 0;
              if (this.filters.levels.findIndex(x => x.id === gallery.workouts[i].level.id) > -1) {
                filterLevel = 1;
              }
            }

            // Filtrado por material
            if (!this.objectsAreSame(this.filters.materials, this.filtersDefault.materials)) {
              filterMaterial = 0;
              gallery.workouts[i].materials.forEach(material => {
                if (this.filters.materials.findIndex(x => x.id === material.id) > -1) {
                  filterMaterial = 1;
                }
              });
            }

            // Borramos elemento si no cumple ningún filtro
            if (filterString === 0 || filterTime === 0 || filterLevel === 0 || filterMaterial === 0) {
              gallery.workouts.splice(i, 1);
            }

          }
        });
      }
    }
  }

  public objectsAreSame(x, y) {
    let objectsAreSame = true;
    for (const propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  }

}

@Component({
  selector: 'app-filtermodal-dialog',
  templateUrl: 'filtermodal.component.html',
})
export class FilterModalComponent {

  public urlMaterialImages = constants.urlMedia + 'materials/';
  public sliderRefresh: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private events: EventsService,
    public dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {

    this.dialogRef.afterOpened().subscribe(() => this.sliderRefresh.emit());
  }

  public filterChanged() {
    this.events.doEvent({ key: 'workout-filter-changed', value: 'true' });
  }

  public materialChanged(material) {
    const nomaterial = this.data.materialList[0];
    if (material && this.data.filters.materials.findIndex(x => x.id === material.id) === -1) {
      this.data.filters.materials.push(material);
    } else {
      const index = this.data.filters.materials.findIndex(x => x.id === material.id);
      this.data.filters.materials.splice(index, 1);
    }
    if (material && material.id !== 0 && this.data.filters.materials.findIndex(x => x.id === 0) === -1) {
      this.data.filters.materials.push(nomaterial);
    }

    this.filterChanged();
  }
  public isChecked(element: any, searchIn: any[]) {
    return searchIn.findIndex(x => x.id === element.id) > -1;
  }

  public resetFilters() {
    this.data.filters.filterText = this.data.filtersDefault.filterText;
    this.data.filters.minTime = this.data.filtersDefault.minTime;
    this.data.filters.maxTime = this.data.filtersDefault.maxTime;
    this.data.filters.levels = this.data.filtersDefault.levels;
    // this.data.filters.materials = this.data.filtersDefault.materials;
    this.data.filters.materials.length = 0;

    this.filterChanged();
  }

  public isMaterialChecked(material) {
    // console.log(material.id);
    // console.log(this.data.filters.materials);
    // console.log(this.data.filters.materials.findIndex(x => x.id === material.id) );
    // indexOf(x => x.id === material.id));
    return this.data.filters.materials.findIndex(x => x.id === material.id) > -1;
  }

}