import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { Workout, WorkoutTag } from './../../interfaces/workout';
import { Component, OnInit } from '@angular/core';
import { constants } from './../../../assets/constants';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-workout',
  templateUrl: './workout.component.html',
  styleUrls: ['./workout.component.scss']
})
export class WorkoutComponent implements OnInit {

  workout: Workout;
  public urlMedia;

  public computedSequenceList: WorkoutTag[]; // Listado ordenado de bloques agrupados para mostrar tiempos por cada uno

  public urlMaterialImages = constants.urlMedia + 'materials/';

  public breadcrumb: Breadcrumb[];

  constructor(private route: ActivatedRoute, private router: Router, public globalService: GlobalService) {
    this.workout = this.route.snapshot.data.workout;
    this.urlMedia = constants.urlMedia;
    this.computedSequenceList = [];
    this.workout.playingWorkout = false;
    this.generateComputedSequenceList();

    this.breadcrumb = [
      {
        name: 'HOME',
        route: '/',
        translate: true
      },
      {
        name: 'WORKOUTS',
        route: '/workouts',
        translate: true
      },
      {
        name: this.workout.name,
        route: '/workout/' + this.workout.id,
        translate: false
      }
    ];
  }

  ngOnInit(): void {
  }

  // Recorremos playerSequence y extraemos y agrupamos las secciones
  private generateComputedSequenceList() {
    if (this.workout.playerSequence !== null && this.workout.playerSequence !== undefined) {
      this.workout.playerSequence.forEach(block => {
        if (block.tags !== null && block.tags !== undefined) {
          block.tags.forEach(tag => {
            const i = this.computedSequenceList.findIndex(item => item.name === tag.name);
            if (i > -1 && tag.duration > 0) {
              this.computedSequenceList[i].duration += tag.duration;
            } else {
              tag.initTime = block.initTime;
              this.computedSequenceList.push(tag);
            }
          });
        }
      });
    }
  }

  public playWorkout() {
    this.workout.playingWorkout = true;
    // this.router.navigateByUrl('player/' + this.workout.id);
  }
  

}
