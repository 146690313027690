<div class="workout-filter w-100" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
            <mat-form-field class="filter-text-field">
                <input matInput type="text" [(ngModel)]="filters.filterText" class="filter-text"
                    (input)="this.updateFilter()">
                <button matSuffix mat-icon-button disableRipple aria-label="Search" class="filter-search-button">
                    <span class="mdi mdi-magnify"></span>
                </button>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between center">
            <button matSuffix mat-icon-button disableRipple aria-label="Search" class="filter-settings-button"
                (click)="openFilterModal()">
                <span class="mdi mdi-tune"></span>
            </button>
            <div class="filters-icon-text">{{'FILTER.TITLE' | translate }}</div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between stretch" class="filters-applied mb-1" *ngIf="isFiltered()">
        <div fxLayout="row" fxLayoutAlign="center stretch">
            <div class="filters-title">{{'FILTER.APPLIED_FILTERS' | translate}}</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around stretch">
            <div fxLayout="column" fxLayoutAlign="center center"
                *ngIf="this.filters.filterText !== this.filtersDefault.filterText" fxFlex="25">
                <div class="small-section-title">{{'FILTER.TEXT_MATCH' | translate}}</div>
                <div class="label-black-small">{{this.filters.filterText}}</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center"
                *ngIf="this.filters.minTime !== this.filtersDefault.minTime || this.filters.maxTime !== this.filtersDefault.maxTime"
                fxFlex="25">
                <div class="small-section-title">{{'FILTER.WORKOUT_TIME' | translate}}</div>
                <div fxLayout="row wrap" fxLayoutAlign="center center">
                    <div class="label-black-small">{{this.filters.minTime}} min</div>
                    <div class="label-black-small">{{this.filters.maxTime}} min</div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.filters.levels.length > 0" fxFlex="25">
                <div class="small-section-title">{{'FILTER.LEVEL' | translate}}</div>
                <div fxLayout="row wrap" fxLayoutAlign="center center">
                    <div class="label-black-small" *ngFor="let level of this.filters.levels">{{level.name}}</div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.filters.materials.length > 0" fxFlex="25">
                <div class="small-section-title">{{'FILTER.MATERIAL' | translate}}</div>
                <div fxLayout="row wrap" fxLayoutAlign="center center">
                    <div class="label-black-small" *ngFor="let material of this.filters.materials">{{material.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>