<div class="heartrate-container" *ngIf="heartRateStep != 0">
    <div class="heartrate-modal">
        <div class="section-title-big text-center mt-0 mb-2">{{ 'PLAYER.HEART_RATE' | translate }}</div>

        <!-- Link HR monitor -->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep==1">
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="startLinkProcess()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-heart-outline"></span>
                    <div fxFlex>
                        {{'PLAYER.LINK_HR_MONITOR' | translate }}
                    </div>
                </div>
            </button>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="startWithoutHR()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-close"></span>
                    <div fxFlex>
                        {{'PLAYER.NO_LINK_HR_MONITOR' | translate }}
                    </div>
                </div>
            </button>
        </div>

        <!-- Profile check -->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep==2">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100">
                <span class="mdi mdi-exclamation icon-warning"></span>
                <h4 class="text-center w-100">{{'PLAYER.GO_TO_PROFILE_WARNING' | translate }}</h4>
            </div>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="goToMyProfile()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-account-outline"></span>
                    <div fxFlex>
                        {{'PLAYER.GO_TO_PROFILE' | translate }}
                    </div>
                </div>
            </button>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="startWithoutHR()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-close"></span>
                    <div fxFlex>
                        {{'PLAYER.NO_LINK_HR_MONITOR' | translate }}
                    </div>
                </div>
            </button>
        </div>

        <!-- Heart rate On check -->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep==3">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100">
                <img src="/assets/img/heartrate.png" class="image-heartrate" />
                <h4 class="text-center w-100">{{'PLAYER.PUT_ON_HR' | translate }}</h4>
            </div>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="nextHeartRateStep()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-skip-next-outline"></span>
                    <div fxFlex>
                        {{'PLAYER.NEXT_PHASE' | translate }}
                    </div>
                </div>
            </button>
        </div>

        <!-- Bluetooth On check -->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep==4">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100">
                <span class="mdi mdi-bluetooth icon-warning"></span>
                <h4 class="text-center w-100">{{'PLAYER.BLUETOOTH_WARNING' | translate }}</h4>
            </div>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="startBLEConnection()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-skip-next-outline"></span>
                    <div fxFlex>
                        {{'PLAYER.NEXT_PHASE' | translate }}
                    </div>
                </div>
            </button>
        </div>

        <!-- Device list find (Mobile)-->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep>=5 && heartRateStep <5.5">
            <div *ngIf="heartRateStep==5">
                <h4 class="text-center w-100" *ngIf="this.devices.length > 0">{{'PLAYER.FOUND_DEVICES' | translate }}
                </h4>
                <h4 class="text-center w-100" *ngIf="this.devices.length == 0">{{'PLAYER.NO_DEVICES' | translate }}</h4>
                <mat-list role="list">
                    <div *ngFor="let device of this.devices">
                        <mat-divider></mat-divider>
                        <mat-list-item role="listitem" (click)="connectBLE(device.id)">
                            <mat-icon mat-list-icon>bluetooth</mat-icon>
                            <div>{{device.name}}</div>
                        </mat-list-item>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100"
                *ngIf="heartRateStep==5.1">
                <mat-spinner></mat-spinner>
                <h4 class="text-center w-100">{{'PLAYER.CONNECTING' | translate }}</h4>                
            </div>
        </div>

        <!-- Follow browser instructions (Web)-->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep>=5.5 && heartRateStep <6">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100"
                *ngIf="heartRateStep==5.5">
                <mat-spinner></mat-spinner>
                <h4 class="text-center w-100">{{'PLAYER.BROWSER_INSTRUCTIONS' | translate }}</h4>
            </div>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100" (click)="requestValue()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-bluetooth"></span>
                    <div fxFlex>
                        {{'PLAYER.DEVICE_LIST' | translate }}
                    </div>
                </div>
            </button>
        </div>

        <!-- Heart rate connected -->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep==6">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100">
                <span class="mdi mdi-check icon-warning"></span>
                <h4 class="text-center w-100">{{'PLAYER.BLUETOOTH_CONNECTED' | translate }}</h4>
                <span *ngIf="this.peripheralData !== null">{{this.peripheralData.name}}</span>
            </div>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100" (click)="startWorkout()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-play-outline"></span>
                    <div fxFlex>
                        {{'PLAYER.START_WORKOUT' | translate }}
                    </div>
                </div>
            </button>
        </div>

        <!-- Heart rate error -->
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" *ngIf="heartRateStep==7">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="w-100">
                <span class="mdi mdi-close icon-warning"></span>
                <h4 class="text-center w-100">{{'PLAYER.BLUETOOTH_ERROR' | translate }}</h4>
            </div>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="startLinkProcess()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-heart-outline"></span>
                    <div fxFlex>
                        {{'PLAYER.TRY_AGAIN' | translate }}
                    </div>
                </div>
            </button>
            <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                (click)="startWithoutHR()">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <span class="mdi mdi-play-outline"></span>
                    <div fxFlex>
                        {{'PLAYER.START_WORKOUT_NO_HR' | translate }}
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>