import { Component, OnInit } from '@angular/core';
import { Gallery } from 'src/app/interfaces/gallery';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  galleriesNew: Gallery[];
  galleriesPopular: Gallery[];

  constructor(private route: ActivatedRoute) {
    this.galleriesNew = this.route.snapshot.data.galleriesNew.galleries;
    this.galleriesPopular = this.route.snapshot.data.galleriesPopular.galleries;
  }


  ngOnInit() {
  }

}
