<!-- <span>
    <button (click)="requestValue()">REQUEST VALUE</button>
    {{this.heartRateValue}} - ({{this.heartRatePercentage}}%)
</span> -->

<!-- DESKTOP -->
<div class="player-container" fxLayout="column" fxLayoutAlign="space-between stretch" fxFlexFill
    [ngClass]="{'player-full-screen': fullScreen}" *ngIf="!this.globalService.isMobile">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!this.workoutFinished">
        <div class="section-title-big text-center mb-0">
            <div>
                <span
                    *ngIf="currentBlocks[0] !== undefined && currentBlocks[0].tags && currentBlocks[0].tags.length > 0">{{
                    currentBlocks[0].tags[0].name }}
                </span>
            </div>
        </div>
    </div>
    <div class="blocks-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch"
        fxLayoutGap="20px" fxFlex fxFlexFill [ngClass]="{'blocks-full-screen-mobile-landscape': globalService.isMobile}"
        *ngIf="!this.workoutFinished">
        <!-- <div *ngFor="let currentBlock of currentBlocks" fxLayout="column" fxLayoutAlign="space-between stretch"
            fxFlex="75"> -->
        <div class="player-block" fxFlex="75">
            <app-block [mainTime]="mainTime" [block]="currentBlocks[0]" [startBlock]="false"
                [nextCountdown]="getTimeToEndCurrentExercise()" [mainVolume]="mainVolume" class="player-block" fxFlex
                fxLayoutAlign="space-between stretch"></app-block>
        </div>
        <!-- </div> -->

        <div class="player-block" fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="25">
            <div *ngIf="heartRate.value > 0">
                <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                    <circle-progress class="h-200px" [percent]="getBpmPercentage()" [radius]="40" [outerStrokeWidth]="6"
                        [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="HROuterCircleColor"
                        [innerStrokeColor]="HRInnerCircleColor" [animation]="true" [animationDuration]="200"
                        [responsive]="true" [startFromZero]="false" [title]="getBpmPercentage() + '%'"
                        [titleFontSize]="30" [showUnits]="false" [showSubtitle]="false" [animateTitle]="false">
                    </circle-progress>
                    <div class="circle-title">{{'PLAYER.CIRCLE_HR' | translate}}</div>
                </div>
            </div>

            <div fxFlex="50" fxLayoutAlign="center end">
                <div class="section-title" *ngIf="nextBlock !== null && nextBlock.type === 1">Next exercise</div>
            </div>
            <app-block [mainTime]="mainTime" [block]="nextBlock" [startBlock]="false" [isNext]="true" fxFlex="50">
            </app-block>
        </div>

    </div>
    <div
        *ngIf="this.currentBlocks[0] && this.currentBlocks[0].nExercise && this.currentBlocks[0].nRound && !this.workoutFinished">
        <div fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

                <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                    <circle-progress [percent]="getExerciseCompletedPercentage()" [radius]="60" [outerStrokeWidth]="7"
                        [innerStrokeWidth]="7" [space]="-7" [outerStrokeColor]="timeOuterCircleColor"
                        [innerStrokeColor]="timeInnerCircleColor" [animation]="true" [animationDuration]="200"
                        [startFromZero]="false" [title]="getTimeToEndCurrentExercise()" [titleFontSize]="55"
                        [showUnits]="false" [showSubtitle]="false" [animateTitle]="false"></circle-progress>
                    <div class="circle-title">{{'PLAYER.CIRCLE_TIME' | translate}}</div>
                </div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                    <circle-progress [percent]="getRoundExercisesCompletedPercentage()" [radius]="60"
                        [outerStrokeWidth]="7" [innerStrokeWidth]="7" [space]="-7" [outerStrokeColor]="'#9C9D9F'"
                        [innerStrokeColor]="timeInnerCircleColor" [animation]="true" [animationDuration]="200"
                        [startFromZero]="false"
                        [title]="this.currentBlocks[0].nExercise +'/' + this.currentBlocks[0].totalExercise"
                        [titleFontSize]="40" [showUnits]="false" [showSubtitle]="false" [animateTitle]="false">
                    </circle-progress>
                    <div class="circle-title" *ngIf="this.currentBlocks[0].phase!=4">
                        {{'PLAYER.CIRCLE_EXERCISE' | translate}}</div>
                    <div class="circle-title" *ngIf="this.currentBlocks[0].phase==4">
                        {{'PLAYER.WORKOUTMODE' + workout.workoutType + '_EXERCISE' | translate}}</div>
                </div>
                <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                    <circle-progress [percent]="getRoundsCompletedPercentage()" [radius]="60" [outerStrokeWidth]="7"
                        [innerStrokeWidth]="7" [space]="-7" [outerStrokeColor]="'#9C9D9F'"
                        [innerStrokeColor]="timeInnerCircleColor" [animation]="true" [animationDuration]="200"
                        [startFromZero]="false"
                        [title]="this.currentBlocks[0].nRound +'/' + this.currentBlocks[0].totalRounds"
                        [titleFontSize]="40" [showUnits]="false" [showSubtitle]="false" [animateTitle]="false">
                    </circle-progress>
                    <div class="circle-title" *ngIf="this.currentBlocks[0].phase!=4">
                        {{'PLAYER.CIRCLE_ROUND' | translate}}</div>
                    <div class="circle-title" *ngIf="this.currentBlocks[0].phase==4">
                        {{'PLAYER.WORKOUTMODE' + workout.workoutType + '_ROUND' | translate}}</div>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="!this.workoutFinished">
        <!-- <div class="player-maintime" [ngClass.gt-xs]="'pl-1 pr-1 pb-1 mb-1'" fxLayout="row"
            fxLayoutAlign="space-between start">
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="label-black-small player-maintime-label">
                    {{ currentComputedSequence.name }}
                </div>
                <div class="player-time-box">{{sessionTime | totime}}</div>
            </div>
            <mat-slider class="w-100" min="0" [max]="currentComputedSequence.duration" [(ngModel)]="sessionTime"
                thumbLabel="false" disabled>
            </mat-slider>

            <div fxLayout="column" fxLayoutAlign="center end">
                <div class="label-black-small player-maintime-label label-black-disabled">
                    {{ getNextComputedSequence().name }}
                </div>
                <div class="player-time-box">{{currentComputedSequenceRemainingTime() | totime}}</div>
            </div>

        </div> -->
        <div class="w-100" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="20px">

            <div fxFlex="33.3333" fxLayoutAlign="start center">
                <app-volumeslider [(currentVolume)]="mainVolume" (volumeChanged)="volumeChanged($event)"
                    *ngIf="!globalService.isMobile"></app-volumeslider>
            </div>

            <div fxFlex="33.3333" fxLayoutAlign="center center">
                <button mat-button class="mat-button-icon" (click)="goToPreviousExercise()" disableRipple
                    [ngClass]="{'disabled': !getPreviousStep()}">
                    <span class="mdi mdi-skip-previous-outline"></span>
                </button>
                <button mat-button class="mat-button-icon" (click)="pauseMainTimer()" disableRipple
                    *ngIf="!workoutFinished">
                    <span class="mdi mdi-pause"></span>
                </button>
                <button class="mat-button-icon" mat-button (click)="goToNextExercise()" disableRipple
                    [ngClass]="{'disabled': !getNextStep()}">
                    <span class="mdi mdi-skip-next-outline"></span>
                </button>
            </div>

            <div fxFlex="33.3333" fxLayoutAlign="end center">
                <button mat-button class="mat-button-icon" (click)="toggleFullScreen()" disableRipple
                    *ngIf="!fullScreen"><span class="mdi mdi-fullscreen"></span></button>
                <button mat-button class="mat-button-icon" (click)="toggleFullScreen()" disableRipple
                    *ngIf="fullScreen"><span class="mdi mdi-fullscreen-exit"></span></button>
            </div>
            <!-- <button mat-button (click)="resumeMainTimer()" *ngIf="!mainTimerRunning">RESUME</button> -->

        </div>
        <div class="pause-container" *ngIf="!mainTimerRunning && workoutStarted && !workoutFinished">
            <div class="pause-modal">
                <div class="section-title-big text-center mt-0 mb-2">{{ 'PLAYER.PAUSE_TITLE' | translate }}</div>
                <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px">
                    <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex
                        (click)="resumeMainTimer()">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <span class="mdi mdi-play-outline"></span>
                            <div fxFlex>
                                {{'PLAYER.PAUSE_MODAL_RESUME' | translate }}
                            </div>
                        </div>
                    </button>
                    <button mat-button class="mat-button-modal-big w-100"
                        [disabled]="getNextComputedSequence().name === 'FINISH'" (click)="goToNextPhase()" fxFlex>
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <span class="mdi mdi-skip-next-outline"></span>
                            <div fxFlex>
                                {{'PLAYER.PAUSE_MODAL_NEXT_PHASE' | translate }}
                            </div>
                        </div>
                    </button>
                    <div class="w-100" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px"
                        *ngIf="quitQuestion">
                        <h4 class="text-center w-100">quit, sure?</h4>
                        <div class="w-100" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
                            <button mat-button class="mat-button-modal-big w-100" fxFlex (click)="cancelQuit()">
                                <span class="mdi mdi-close"></span>
                            </button>
                            <button mat-button class="mat-button-modal-big w-100" fxFlex (click)="quitWorkout()">
                                <span class="mdi mdi-check"></span>
                            </button>
                        </div>
                    </div>
                    <button mat-button class="mat-button-modal-big w-100" fxFlex *ngIf="!quitQuestion"
                        (click)="quitAsk()">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <span class="mdi mdi-close"></span>
                            <div fxFlex>
                                {{'PLAYER.PAUSE_MODAL_QUIT' | translate }}
                            </div>
                        </div>
                    </button>

                </div>
            </div>
        </div>
    </div>

    <app-heartrate (start)="startWorkout()" [(heartRate)]="this.heartRate" [userMaxBpm]="this.userMaxBpm"
        [isMobile]="false"></app-heartrate>

    <!-- DEBUG WINDOW -->
    <!-- <div class="debug-window" *ngIf="!this.workoutFinished">
        <h4 class="no-margin">Player debug panel</h4>
        <mat-divider></mat-divider>
        <pre>
            mainTime: {{mainTime}}
            FullScreen: {{this.fullScreen}}
            currentBlock: {{this.currentBlocks[0].id}}
            prevBlock initTime: {{this.previousBlock?.initTime}}
            nextBlock initTime: {{this.nextBlock?.initTime}}
        </pre>
        <button (click)="this.mainTime = this.mainTime + 5; processBlocks(); resumeMainTimer()">+5
            segs</button>
        <button (click)="this.mainTime = this.mainTime + 20; processBlocks(); resumeMainTimer()">+20
            segs</button>
        <button (click)="this.mainTime = this.mainTime + 60; processBlocks(); resumeMainTimer()">+1
            min</button>
    </div> -->

    <div class="player-finished-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"
        *ngIf="this.workoutFinished">
        <div class="label-black-medium">
            {{'PLAYER.WORKOUT_FINISHED_1' | translate}}<br>
            {{'PLAYER.WORKOUT_FINISHED_2' | translate}}
        </div>
        <button mat-button class="mat-button-modal-big" (click)="quitWorkout()">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                <span class="mdi mdi-close"></span>
                <div fxFlex>
                    {{'PLAYER.PAUSE_MODAL_QUIT' | translate }}
                </div>
            </div>
        </button>
    </div>


</div>


<!-- MOBILE -->
<div class="player-container mobile-part" fxLayout="column" fxLayoutAlign="space-between stretch" fxFlexFill
    [ngClass]="{'player-full-screen': fullScreen}" *ngIf="this.globalService.isMobile">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!this.workoutFinished">
        <div class="section-title-big text-center mb-0">
            <div>
                <span
                    *ngIf="currentBlocks[0] !== undefined && currentBlocks[0].tags && currentBlocks[0].tags.length > 0">{{
                    currentBlocks[0].tags[0].name }}
                </span>
            </div>
        </div>
    </div>
    <div class="blocks-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch"
        fxLayoutGap="20px" fxFlex fxFlexFill [ngClass]="{'blocks-full-screen-mobile-landscape': globalService.isMobile}"
        *ngIf="!this.workoutFinished">
        <!-- <div *ngFor="let currentBlock of currentBlocks" fxLayout="column" fxLayoutAlign="space-between stretch"
            fxFlex="75"> -->
        <div class="player-block" fxFlex="60">
            <app-block [mainTime]="mainTime" [block]="currentBlocks[0]" [startBlock]="false"
                [nextCountdown]="getTimeToEndCurrentExercise()" [mainVolume]="mainVolume" class="player-block" fxFlex
                fxLayoutAlign="space-between stretch"></app-block>
        </div>
        <!-- </div>         -->

        <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="40">
            <div class="player-block" fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="50">
                <div fxLayoutAlign="center end">
                    <div class="section-title no-margin" *ngIf="nextBlock !== null && nextBlock.type === 1">Next
                        exercise
                    </div>
                </div>
                <app-block [mainTime]="mainTime" [block]="nextBlock" [startBlock]="false" [isNext]="true"
                    class="player-block" fxFlex fxLayoutAlign="space-between stretch">
                </app-block>
            </div>
            <div class="player-block blank-space-block" fxLayout="column" fxLayoutAlign="space-between stretch"
                fxFlex="50">
                <div *ngIf="heartRate.value > 0">
                    <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                        <circle-progress [percent]="getBpmPercentage()" [radius]="40" [outerStrokeWidth]="6"
                            [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="HROuterCircleColor"
                            [innerStrokeColor]="HRInnerCircleColor" [animation]="true" [animationDuration]="200"
                            [responsive]="true" [startFromZero]="false" [title]="getBpmPercentage() + '%'"
                            [titleFontSize]="30" [showUnits]="false" [showSubtitle]="false" [animateTitle]="false">
                        </circle-progress>
                        <div class="circle-title">{{'PLAYER.CIRCLE_HR' | translate}}</div>
                    </div>
                </div>
                <!-- {{heartRate.value}}             -->
            </div>
        </div>
    </div>
    <div class="circle-stats"
        *ngIf="this.currentBlocks[0] && this.currentBlocks[0].nExercise && this.currentBlocks[0].nRound && !this.workoutFinished">
        <div fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex="33.3333" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                    <circle-progress [percent]="getExerciseCompletedPercentage()" [radius]="40" [outerStrokeWidth]="6"
                        [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="timeOuterCircleColor"
                        [innerStrokeColor]="timeInnerCircleColor" [animation]="true" [animationDuration]="200"
                        [responsive]="true" [startFromZero]="false" [title]="getTimeToEndCurrentExercise()"
                        [titleFontSize]="30" [showUnits]="false" [showSubtitle]="false" [animateTitle]="false">
                    </circle-progress>
                    <div class="circle-title">{{'PLAYER.CIRCLE_TIME' | translate}}</div>
                </div>
            </div>
            <div fxFlex="33.3333" fxLayout="column" fxLayoutAlign="space-between center">
                <circle-progress [percent]="getRoundExercisesCompletedPercentage()" [radius]="40" [outerStrokeWidth]="6"
                    [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="'#9C9D9F'"
                    [innerStrokeColor]="timeInnerCircleColor" [animation]="true" [animationDuration]="200"
                    [responsive]="true" [startFromZero]="false"
                    [title]="this.currentBlocks[0].nExercise +'/' + this.currentBlocks[0].totalExercise"
                    [titleFontSize]="30" [showUnits]="false" [showSubtitle]="false" [animateTitle]="false">
                </circle-progress>
                <div class="circle-title" *ngIf="this.currentBlocks[0].phase!=4">
                    {{'PLAYER.CIRCLE_EXERCISE' | translate}}</div>
                <div class="circle-title" *ngIf="this.currentBlocks[0].phase==4">
                    {{'PLAYER.WORKOUTMODE' + workout.workoutType + '_EXERCISE' | translate}}</div>
            </div>
            <div fxFlex="33.3333" fxLayout="column" fxLayoutAlign="space-between center">
                <circle-progress [percent]="getRoundsCompletedPercentage()" [radius]="40" [outerStrokeWidth]="6"
                    [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="'#9C9D9F'"
                    [innerStrokeColor]="timeInnerCircleColor" [animation]="true" [animationDuration]="200"
                    [responsive]="true" [startFromZero]="false"
                    [title]="this.currentBlocks[0].nRound +'/' + this.currentBlocks[0].totalRounds" [titleFontSize]="30"
                    [showUnits]="false" [showSubtitle]="false" [animateTitle]="false">
                </circle-progress>
                <div class="circle-title" *ngIf="this.currentBlocks[0].phase!=4">
                    {{'PLAYER.CIRCLE_ROUND' | translate}}</div>
                <div class="circle-title" *ngIf="this.currentBlocks[0].phase==4">
                    {{'PLAYER.WORKOUTMODE' + workout.workoutType + '_ROUND' | translate}}</div>
            </div>


        </div>
    </div>
    <div *ngIf="!this.workoutFinished">

        <div class="w-100" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="20px">

            <div fxFlex="33.3333" fxLayoutAlign="start center">
                <app-volumeslider [(currentVolume)]="mainVolume" (volumeChanged)="volumeChanged($event)"
                    *ngIf="!globalService.isMobile"></app-volumeslider>
            </div>

            <div fxLayoutAlign="center center">
                <button mat-button class="mat-button-icon" (click)="goToPreviousExercise()" disableRipple
                    [ngClass]="{'disabled': !getPreviousStep()}">
                    <span class="mdi mdi-skip-previous-outline"></span>
                </button>
                <button mat-button class="mat-button-icon" (click)="pauseMainTimer()" disableRipple
                    *ngIf="!workoutFinished">
                    <span class="mdi mdi-pause"></span>
                </button>
                <button class="mat-button-icon" mat-button (click)="goToNextExercise()" disableRipple
                    [ngClass]="{'disabled': !getNextStep()}">
                    <span class="mdi mdi-skip-next-outline"></span>
                </button>
            </div>

            <div fxFlex="33.3333" fxLayoutAlign="end center">
                <!-- <button mat-button class="mat-button-icon" (click)="toggleFullScreen()" disableRipple
                    *ngIf="!fullScreen"><span class="mdi mdi-fullscreen"></span></button>
                <button mat-button class="mat-button-icon" (click)="toggleFullScreen()" disableRipple
                    *ngIf="fullScreen"><span class="mdi mdi-fullscreen-exit"></span></button> -->
            </div>
            <!-- <button mat-button (click)="resumeMainTimer()" *ngIf="!mainTimerRunning">RESUME</button> -->

        </div>

        <div class="pause-container" *ngIf="!mainTimerRunning && workoutStarted && !workoutFinished">
            <div class="pause-modal">
                <div class="section-title-big text-center mt-0 mb-2">{{ 'PLAYER.PAUSE_TITLE' | translate }}</div>
                <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px">
                    <button mat-button class="mat-button-modal-big w-100" cdkFocusInitial fxFlex="100"
                        (click)="resumeMainTimer()">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <span class="mdi mdi-play-outline"></span>
                            <div fxFlex>
                                {{'PLAYER.PAUSE_MODAL_RESUME' | translate }}
                            </div>
                        </div>
                    </button>
                    <button mat-button class="mat-button-modal-big w-100"
                        [disabled]="getNextComputedSequence().name === 'FINISH'" (click)="goToNextPhase()" fxFlex="100">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <span class="mdi mdi-skip-next-outline"></span>
                            <div fxFlex>
                                {{'PLAYER.PAUSE_MODAL_NEXT_PHASE' | translate }}
                            </div>
                        </div>
                    </button>
                    <div class="w-100" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px"
                        *ngIf="quitQuestion">
                        <h4 class="text-center w-100">{{'PLAYER.PAUSE_MODAL_QUIT_ASK' | translate }}</h4>
                        <div class="w-100" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
                            <button mat-button class="mat-button-modal-big w-100" fxFlex (click)="cancelQuit()">
                                <span class="mdi mdi-close"></span>
                            </button>
                            <button mat-button class="mat-button-modal-big w-100" fxFlex (click)="quitWorkout()">
                                <span class="mdi mdi-check"></span>
                            </button>
                        </div>
                    </div>
                    <button mat-button class="mat-button-modal-big w-100" fxFlex="100" *ngIf="!quitQuestion"
                        (click)="quitAsk()">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <span class="mdi mdi-close"></span>
                            <div fxFlex>
                                {{'PLAYER.PAUSE_MODAL_QUIT' | translate }}
                            </div>
                        </div>
                    </button>

                </div>
            </div>
        </div>
    </div>

    <app-heartrate (start)="startWorkout()" [(heartRate)]="this.heartRate" [userMaxBpm]="this.userMaxBpm"
        [isMobile]="true"></app-heartrate>

    <div class="player-finished-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"
        *ngIf="this.workoutFinished">
        <div class="label-black-medium">
            {{'PLAYER.WORKOUT_FINISHED_1' | translate}}<br>
            {{'PLAYER.WORKOUT_FINISHED_2' | translate}}
        </div>
        <button mat-button class="mat-button-modal-big" (click)="quitWorkout()">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                <span class="mdi mdi-close"></span>
                <div fxFlex>
                    {{'PLAYER.PAUSE_MODAL_QUIT' | translate }}
                </div>
            </div>
        </button>
    </div>

    <!-- DEBUG WINDOW -->
    <!-- <div class="debug-window" *ngIf="!this.workoutFinished">
        <h4 class="no-margin">Player debug panel</h4>
        <mat-divider></mat-divider>
        <pre>
            mainTime: {{mainTime}}
            FullScreen: {{this.fullScreen}}
            currentBlock: {{this.currentBlocks[0].id}}
            prevBlock initTime: {{this.previousBlock?.initTime}}
            nextBlock initTime: {{this.nextBlock?.initTime}}
        </pre>
        <button (click)="this.mainTime = this.mainTime + 5; processBlocks(); resumeMainTimer()">+5
            segs</button>
        <button (click)="this.mainTime = this.mainTime + 20; processBlocks(); resumeMainTimer()">+20
            segs</button>
        <button (click)="this.mainTime = this.mainTime + 60; processBlocks(); resumeMainTimer()">+1
            min</button>
    </div> -->
</div>