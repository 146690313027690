import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-volumeslider',
  templateUrl: './volumeslider.component.html',
  styleUrls: ['./volumeslider.component.scss']
})
export class VolumesliderComponent implements OnInit {

  @Input() currentVolume: number;

  @Output() volumeChanged = new EventEmitter<number>();

  public volumeSliderVisible: boolean;

  constructor() {
    this.volumeSliderVisible = false;
  }

  ngOnInit(): void {
  }

  public toggleVolumeSlider(){
    this.volumeSliderVisible = !this.volumeSliderVisible;
  }

  public volumeChange(){
    this.volumeChanged.emit(this.currentVolume);    
  }

}
