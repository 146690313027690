import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GalleryService } from '../services/gallery.service';

@Injectable()
export class GalleryNewResolver implements Resolve<Observable<string>> {

    constructor(private galleryService: GalleryService) { }

    resolve() {
        return this.galleryService.getNew().pipe(catchError(() => {
            return of('error');
        }));
    }
}

@Injectable()
export class GalleryPopularResolver implements Resolve<Observable<string>> {

    constructor(private galleryService: GalleryService) { }

    resolve() {
        return this.galleryService.getPopular().pipe(catchError(() => {
            return of('error');
        }));
    }
}

@Injectable()
export class GalleryByProgramResolver implements Resolve<Observable<string>> {

    constructor(private galleryService: GalleryService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.galleryService.getByProgram(route.data.galleryType).pipe(catchError(() => {
            return of('error');
        }));
    }
}


