import { WorkoutSequenceBlock } from './../../../interfaces/workout';
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { constants } from '../../../../assets/constants';
import Player from '@vimeo/player';
import { EventsService } from 'src/app/services/events.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent implements OnInit, AfterViewInit {

  public urlExerciseGifs;

  public videoPlayer;

  @Input() mainTime: number;
  @Input() block: WorkoutSequenceBlock;
  @Input() startBlock: boolean;
  @Input() isNext: boolean;
  @Input() nextCountdown: number;
  @Input() mainVolume: number;

  // @ViewChild('playerContainer') playerContainer;

  constructor(private events: EventsService, public globalService: GlobalService) {
    this.urlExerciseGifs = constants.urlExerciseGifs;

    // if (this.block !== undefined && this.block.type === 2 && !this.isNext) {
    //   this.events.event$.subscribe((params: any) => {
    //     if (params.key === 'workout-status') {
    //       if (params.value === 'paused') {
    //         this.pauseVideo();
    //       }
    //       if (params.value === 'playing') {
    //         // Al recibir evento RESUME actualizamos posición de vídeo
    //         // if(this.mainTime - this.block.initTime < this.videoPlayer.getDuration()){
    //         this.videoPlayer.setCurrentTime(this.mainTime - this.block.initTime).catch(function (error) {
    //           console.log('video error: ' + error.name)
    //         });
    //         // }
    //         this.playVideo();
    //       }
    //     }
    //     if (params.key === 'volume-changed') {
    //       this.videoPlayer.setVolume(this.mainVolume);
    //     }
    //   });

    // }

  }

  ngOnInit(): void {
    if (this.block !== undefined && this.block.type === 2 && !this.isNext) {
      this.events.event$.subscribe((params: any) => {
        if (params.key === 'workout-status') {
          if (params.value === 'paused') {
            this.pauseVideo();
          }
          if (params.value === 'playing') {
            // Al recibir evento RESUME actualizamos posición de vídeo
            // if(this.mainTime - this.block.initTime < this.videoPlayer.getDuration()){
            this.videoPlayer.setCurrentTime(this.mainTime - this.block.initTime).catch(error => {
              console.log('video error: ' + error.name);
            });
            // }
            this.playVideo();
          }
        }
        if (params.key === 'volume-changed') {
          this.videoPlayer.setVolume(params.value);
        }
      });

    }
  }

  ngAfterViewInit(): void {
    // Vimeo video player
    const videoOptions = {
      id: 333111343,
      responsive: false,
      loop: false,
      autoplay: !this.isNext,
      controls: false,
      volume: this.mainVolume
    };

    // if (this.playerContainer) {
    if (this.block && this.block.type === 2 && !this.isNext) {
      this.videoPlayer = new Player(document.getElementById('playerContainer' + this.block.id), videoOptions);
    }
    // }

    // this.videoPlayer.on('play', function () {
    //   console.log('played the video!');
    // });

    //    this.playVideo();
  }

  public getLayerTitlePositionClasses(block: WorkoutSequenceBlock) {
    const classes = [];
    // block.item.behaviour = 1;
    // block.item.idPosition = 1;
    // block.item.image = 'https://cdn.icon-icons.com/icons2/1621/PNG/512/3741354-weather-wind-windy_108870.png';
    switch (block.item.idPosition) {
      case 0: classes.push('block-text'); break;
      case 1: classes.push('block-title', 'block-top', 'block-left'); break;
      case 2: classes.push('block-title', 'block-top', 'block-right'); break;
      case 3: classes.push('block-title', 'block-top', 'block-center'); break;
      case 4: classes.push('block-title', 'block-bottom', 'block-left'); break;
      case 5: classes.push('block-title', 'block-bottom', 'block-right'); break;
      case 6: classes.push('block-title', 'block-bottom', 'block-center'); break;
    };

    return classes;
  }

  public playVideo() {
    // this.videoPlayer.setVolume(0);
    // this.videoPlayer.on('play', function () {
    //   console.log('played the video!');
    // });
    if (this.videoPlayer) {
      this.videoPlayer.play().catch(error => {
        console.log('video error: ' + error.name);
      });
    }
  }

  public pauseVideo() {
    if (this.videoPlayer) {
      this.videoPlayer.pause();
    }
  }

}
