import { LoginForm } from './../interfaces/logindata';
import { Injectable } from '@angular/core';
import { SessionToken } from '../interfaces/sessiontoken';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, retry, map  } from 'rxjs/operators';
import { LoginData } from '../interfaces/logindata';
import { constants } from '../../assets/constants';
import { GlobalService } from './global.service';
import { EventsService } from './events.service';
import 'rxjs/add/operator/mergeMap';
import { Router } from '@angular/router';
// import { Role } from '../interfaces/role';

@Injectable()
export class AuthService {

  private urlLogin = constants.urlAPI + constants.endpoints.login.post;
  private urlLoginForm = constants.urlAPI + constants.endpoints.loginForm.post;
  private urlValidateToken = constants.urlAPI + constants.endpoints.validateToken.post;
  private urlRefreshToken = constants.urlAPI + constants.endpoints.refreshToken.post;
  // private urlSendResetPasswordEmail = constants.urlAPI + constants.endpoints.resetPassword.get;

  // private user: User;

  constructor(public http: HttpClient,
    private globalService: GlobalService,
    private eventsService: EventsService, 
    private router: Router) { }


  public checkLogin(data: LoginData): Observable<any> {
    let x = data.gym;
    let y: number = +x;
    data.gym = y;
    return this.http.post(this.urlLogin, data);
  }

  public checkLoginForm(data: LoginForm): Observable<any> {    
    return this.http.post(this.urlLoginForm, data);
  }

  public validateOrRefreshToken(): Observable<any> {
    return this.validToken().mergeMap(resp => this.refreshToken(resp));
  }


  private validToken(): Observable<any> {
    const result = {
      valid: false,
      expired: false
    };
    const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
    if (!token || !token.token || token.token === '') {
      return of(result);
    } else {
      return this.http.post(this.urlValidateToken, token);
    }
  }


  private refreshToken(validationData): Observable<any> {
    if (!validationData.valid && validationData.expired) {    
      const result = {
        valid: false,
        expired: false
      };
      const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
      const data = { refreshToken: token.refreshToken };
      return this.http.post(this.urlRefreshToken, data).pipe(
        catchError(error => {
            result.expired = true;
            return of(result);
          })
      ).pipe(map((resp: any) => {
          if (resp && resp.session) {
            this.saveSession(resp);
            result.valid = true;
          } else {

          }
          return result;
        }));
    } else {
      return of(validationData);
    }
  }


  public saveSession(session) {
    localStorage.clear();
    localStorage.setItem(constants.keySession, JSON.stringify(session.session));
    localStorage.setItem(constants.keyRoles, JSON.stringify(session.roles));
  }


  public isLogged() {
    const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
    const roles: number[] = JSON.parse(localStorage.getItem(constants.keyRoles));

    if (token && token != null && roles && roles != null) {
    // if (token && token != null) {
      return true;
    }
    return false;
  }


  public closeSession(): Observable<boolean> {
    localStorage.clear();

    // this.globalService.changePageTitle(['']);
    this.eventsService.doEvent({ key: 'logout', value: 'ok' });

    return of(true);
  }


  public isInRole(requiredRole: number) {
    const roles: number[] = JSON.parse(localStorage.getItem(constants.keyRoles));
    if (roles && roles !== null) {
      return roles.some(x => x === requiredRole);
    }

    return false;
  }

  public checkRole(roles: string[]) {
    let isInRole = false;
    Array.from(roles).forEach(role => {
      if (this.isInRole(constants.roles[role])) {
        isInRole = true;
      }
    });
    return isInRole;
  }

  public logOut(){
    localStorage.removeItem('phsession');
    this.router.navigateByUrl('/login');
  }

  // public checkRoleIds(roles: number[]) {
  //   let isInRole = false;
  //   Array.from(roles).forEach(role => {
  //     if (this.isInRole(role)) {
  //       isInRole = true;
  //     }
  //   });
  //   return isInRole;
  // }

  // public getUserId() {
  //   const user = JSON.parse(localStorage.getItem('pmsession'));
  //   return user.userId;
  // }
  // public getUsername() {
  //   const user = JSON.parse(localStorage.getItem('pmsession'));
  //   return user.user;
  // }

  // public sendResetPasswordEmail(email: string): Observable<any> {
  //   return this.http.get(this.urlSendResetPasswordEmail + '?user=' + email);
  // }

}
