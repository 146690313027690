<div class="inner-container pos-rel" fxFlex>
    <app-sidemenu></app-sidemenu>

    <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="pb-2">
        <!-- <div class="workout-name">
        {{workout.name}}
    </div> -->
        <h2 class="section-title">
            <app-breadcrumb [elements]="breadcrumb"></app-breadcrumb>
        </h2>

        <div class="gallery-card workout-card" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="workout-player" *ngIf="workout.playingWorkout">
                <app-player [workout]="workout" [computedSequenceList]="computedSequenceList"></app-player>
            </div>
            <div *ngIf="!workout.playingWorkout">
                <!-- <div class="slider-card-image" [ngStyle]="{'background-image':'url('+workout.image+')'}"
                *ngIf="!globalService.isMobile">
                <button mat-button class="slider-card-button w-100" (click)="playWorkout()">PLAY</button>
            </div> -->
                <!-- <div class="pos-rel" *ngIf="globalService.isMobile">
                <img [src]="workout.image">
                <button mat-button class="slider-card-button w-100 center-div-both" (click)="playWorkout()">PLAY</button>
            </div> -->
                <div class="pos-rel">
                    <img [src]="workout.image">
                    <div class="overlay-darker"></div>
                    <button mat-button class="slider-card-button w-100 center-div-both"
                        (click)="playWorkout()">PLAY</button>
                </div>
            </div>


            <div class="gallery-card-info pos-rel" fxLayout="row" fxLayout.xs="column"
                fxLayoutAlign="space-between stretch" fxFlex="100">
                <div class="gallery-card-info" fxLayout="column" fxLayoutAlign="start start" fxFlex="100">
                    <div class="gallery-card-info" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
                        fxLayoutGap="30px" class="w-100">
                        <div class="gallery-card-specs" fxLayoutAlign="center center" fxLayoutGap="5px">
                            <div class="label-black-small" *ngIf="workout.program">{{workout.program.name}}</div>
                            <div class="label-black-small" *ngIf="workout.level">{{workout.level.name}}</div>
                            <div class="label-black-small" *ngIf="workout.mode">{{workout.mode.name}}</div>
                        </div>
                        <div class="gallery-card-materials w-100" fxLayout="row wrap" fxLayoutAlign="center center"
                            fxLayoutGap="20px" *ngIf="!globalService.isMobile">
                            <div *ngFor="let material of workout.materials" fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <img class="material-image" [src]="urlMaterialImages + material.image">
                                <div class="label-black-small">{{material.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="slider-card-description" fxFlex="100" [ngClass.xs]="'w-100'" *ngIf="workout.description">
                        <p class="mt-0" [ngClass.xs]="'text-center'">{{workout.description}}</p>
                    </div>
                </div>
                <div class="gallery-card-materials w-100" fxLayout="row wrap" fxLayoutAlign="center center"
                    fxLayoutGap="20px" *ngIf="globalService.isMobile">
                    <div *ngFor="let material of workout.materials" fxLayout="row" fxLayoutAlign="space-between center">
                        <img class="material-image" [src]="urlMaterialImages + material.image">
                        <div class="label-black-small">{{material.name}}</div>
                    </div>
                </div>
                <div class="gallery-card-info" fxLayout="column" fxLayoutAlign="space-between center"
                    [ngClass.xs]="'mb-1'">
                    <div class="slider-card-time-big pb-1" fxLayout="row" fxLayoutAlign="space-between center"
                        fxLayoutGap="10px">
                        <span class="mdi mdi-clock-outline"></span>
                        <span>{{workout.duration | tominutes}} {{'GALLERY.TIME_MINUTES' | translate}}</span>
                    </div>
                    <div class="gallery-time-table" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="5px">
                        <div class="label-black-small" *ngFor="let item of this.computedSequenceList">{{item.name}} -
                            {{item.duration | tominutes}} min</div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass.gt-xs]="'mt-2'" *ngIf="!workout.playingWorkout">
            <app-exercisegallery [workout]="workout"></app-exercisegallery>
        </div>
    </div>
    
</div>