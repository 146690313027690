import { WorkoutMaterial } from './../app/interfaces/workout';
const environment = ''; // 'dev' / 'test' / ''

export const constants = {
  appVersion: '0.9.1',
  appId: 1,

  localUrlAPI: 'http://localhost:4200',
  // urlAPI: 'https://' + environment + 'hfapi.pramahomefitness.com',
  urlAPI: 'https://api.pramahomefitness.com',
  //urlAPI: 'https://api.pramahomefitness.com',
  //urlAPI: 'http://devapi.pramahomefitness.com',

  // qa
  // urlAPI: 'http://qaapi.pramacloud.com',
  // urlAPP: 'http://qamanager.pramacloud.com',

  urlMedia: '/assets/img/',
  // urlExerciseGifs: '/assets/img/gifs/',
  urlExerciseGifs: 'https://creator.pramacloud.com/public/exercises/',
  urlMusic: '/assets/audio/music/',

  endpoints: {
    login: {
      post: '/token/user'
    },
    loginForm: {
      post: '/token/user-login'
    },
    validateToken: {
      post: '/token/user/valid'
    },
    refreshToken: {
      post: '/token/user/renew'
    },
    gallery: {
      getNew: '/home-fitness/workout/new', // Opcional: quantity(x: number = 5)
      getPopular: '/home-fitness/workout/popular', // Opcional: quantity(x: number = 5)
      getPopularByType: '/home-fitness/workout/popular/:id', // Opcional: quantity(x: number = 5)
      getByProgram: '/home-fitness/workout' // Obligatorio: type(x: number) -> 1:WORKOUT, 2:STUDIO_WORKOUT, 3:WORKSHOP
    },
    global: {
      getMaterials: '/home-fitness/material'
    },
    workout: {
      get: '/home-fitness/workout/:id'
    },
    sendData: {
      post: '/home-fitness/workout/records',
      put: '/home-fitness/workout/records'
    }


  },
  keySession: 'phsession',
  keyRoles: 'pmroles',
  keyUsername: 'phusername',

  roles: {
    ph_user: 1
  },

  newSessionDaysAgoGallery: 7,
  newSessionDaysAgoSlider: 7,

  nomaterial: {
    id: 0,
    name: 'No material',
    image: 'nomaterial.png'
  },

  rrss: {
    facebook: 'https://www.facebook.com/pramagym/',
    instagram: 'https://www.instagram.com/prama.fitness/?hl=es',
    twitter: 'https://twitter.com/pavigym',
  },

  sessionPhases: [
    { 1: "Welcome" },
    { 2: "Explanation" },
    { 3: "Warmup" },
    { 4: "Workout" },
    { 5: "Finisher" },
    { 6: "Cooldown" },
    { 7: "Ending" },
  ],

  circleColors: {
    HRColorGray: "#979796",
    HRColorDarkGray: "#636363",
    HRColorBlue: "#66B7D8",
    HRColorDarkBlue: "#467C8C",
    HRColorGreen: "#75B743",
    HRColorDarkGreen: "#617F46",
    HRColorYellow: "#EFE427",
    HRColorDarkYellow: "#A09724",
    HRColorRed: "#D63526",
    HRColorDarkRed: "#A02620",
    HRColorOrange: "#FF7300",
    HRColorDarkOrange: "#C25700",
    ColorBlack: "#000000",
    ColorLightGray: "#DDDDDD",
    ColorDarkGray: "#60B03D",
    ColorGreen: "#60B03D",
  }


};


/*
1º Enpoint para obtener los NEW
GET -> home-fitness/workout/new
Parametro opcional: quantity (Si no se pasa = 5)
09:12

2º Enpoint para obener todos los populares
GET -> home-fitness/workout/popular
Parametro opcional: quantity (Si no se pasa = 5)

3º Endpoint para obtener los populares (Solo de 1 tipo)
GET -> home-fitness/workout/popular/{id:int}
Parametro obligatorio: id
Parametro opcional: quantity (Si no se pasa = 5)

4º Endpoint para obtener listado de programas con sus respectivos workouts.
GET -> home-fitness/workout
Parametro obligatorio: type (Workout = 1, Studio Workout = 2, Workshop = 3)
*/
