import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { BluetoothCore, BrowserWebBluetooth, ConsoleLoggerService } from '@manekinekko/angular-web-bluetooth';
import { Subscription } from 'rxjs';

type ServiceOptions = {
    characteristic: string;
    service: string,
    decoder(value: DataView): number | {[key: string]: number}
    //decoder: (value: DataView) => value.getInt8(1)
};

export const HEART_RATE_SERVICE_UUID = '0000180d-0000-1000-8000-00805f9b34fb';
export const HEART_RATE_MEASUREMENT_CHARACTERISTIC = '00002a37-0000-1000-8000-00805f9b34fb';

// https://github.com/manekinekko/angular-web-bluetooth
@Injectable({
  providedIn: 'root'
})
export class BleService {

  // tslint:disable-next-line: variable-name
  private _config: ServiceOptions;
  
  private _connectedDevice: BluetoothDevice;
  private _hrValue: number;

  get hrValue() {
    return this._hrValue;
  }

  constructor(public ble: BluetoothCore) { }

  config(options: ServiceOptions) {
    this._config = options;
  }

  getDevice() {
    return this.ble.getDevice$();
  }

  stream() {
    return this.ble.streamValues$().pipe(
      map(this._config.decoder)
    );
  }

  value() {
    return this.ble
      .value$({
        service: this._config.service,
        characteristic: this._config.characteristic
      });
  }

  disconnectDevice() {
    this.ble.disconnectDevice();
  }
}