import { ActivatedRoute, Router } from '@angular/router';
import { LoginData, LoginForm } from './../../interfaces/logindata';
import { Component, OnInit } from '@angular/core';
import { constants } from '../../../assets/constants';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginError: boolean;
  public showLoginError: boolean;
  public showSpinner: boolean;

  public loginForm: LoginForm = {
    user: '',
    password: '',
    appId: 1
  };

  constructor(private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.loginError = false;
    this.showLoginError = false;
    this.showSpinner = false;
    this.loginForm.appId = 2; // 1- Web, 2- App
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {

      const data: LoginData = {
        hash: params.token,
        appId: constants.appId,
        gym: params.gym
      };
      if (data.hash && data.gym && !this.authService.isLogged()) {
        this.authService.checkLogin(data)
          .subscribe(resp => {
            this.authService.saveSession(resp);
            localStorage.setItem(constants.keyUsername, this.loginForm.user);
            this.router.navigateByUrl('/');
          }, error => {
            this.loginError = true;
          });
      } else {
        if (this.authService.isLogged()) {
          this.router.navigateByUrl('/');
        } else {
          this.loginError = true;
        }
      }
    });

    if(localStorage.getItem(constants.keyUsername)){
      this.loginForm.user = localStorage.getItem(constants.keyUsername);
    }
  }

  public doLoginForm() {
    this.showLoginError = false;
    this.showSpinner = true;
    this.authService.checkLoginForm(this.loginForm)
      .subscribe(resp => {
        this.showSpinner = false;
        this.showLoginError = false;
        this.authService.saveSession(resp);
        localStorage.setItem(constants.keyUsername, this.loginForm.user);
        this.router.navigateByUrl('/');
      }, error => {
        this.showSpinner = false;
        this.showLoginError = true;
      });
  }

  public hideErrors(){
    this.showLoginError = false;
  }

}
